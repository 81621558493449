import React, { Component } from "react";
import SignIn from "./SignIn";
import Churches from "./Churches";
import Invite from "./Invite";
import TeamsChurches from "./TeamsChurches";
import TeamsInvite from "./TeamsInvite";
import Averages from "./Averages";
import TabMenu from "./TabMenu";
import "./App.css";
import { adminAuthUid, db } from "./constants";
import firebase from "firebase";
import { BrowserRouter, Switch, Redirect, Route, Link } from "react-router-dom";

class App extends Component {
  componentDidMount() {
    this.setState({
      auth: null,
      churches: null,
      teamChurches: null,
      responses: null,
      averages: null,
      customFields: null,
      currentTab: "churches",
      currentTeamsTab: "churches",
      teamsInvites: null,
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ auth: user });
        db.ref("church").on("value", (churchSnapshot) =>
          this.setState({ churches: churchSnapshot.val() })
        );
        db.ref("teamChurch").on("value", (churchSnapshot) =>
          this.setState({ teamChurches: churchSnapshot.val() })
        );
        db.ref("teamInvite").on("value", (invitesSnapshot) =>
          this.setState({ teamsInvites: invitesSnapshot.val() })
        );
        db.ref("response")
          .once("value")
          .then((responseSnapshot) =>
            this.setState({ responses: responseSnapshot.val() })
          );
        db.ref("averages")
          .once("value")
          .then((snapshot) => this.setState({ averages: snapshot.val() }));
        db.ref("customFields")
          .once("value")
          .then((snapshot) => this.setState({ customFields: snapshot.val() }));
      } else {
        this.setState({ auth: null });
      }
    });
  }
  signOut() {
    firebase.auth().signOut();
  }
  signIn(email, password) {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function(error) {
        //var errorCode = error.code;
        //var errorMessage = error.message;
      });
  }
  setTab(tabName) {
    this.setState({ currentTab: tabName });
  }
  setTeamsTab(tabName) {
    this.setState({ currentTeamsTab: tabName });
  }
  render() {
    if (!this.state || !this.state.auth) {
      return <SignIn onSignIn={this.signIn} />;
    }
    if (this.state.auth && this.state.auth.uid === adminAuthUid) {
      return (
        <BrowserRouter>
          <div className="App">
            <div className="App-header">
              <div
                className="container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Link
                    to="/"
                    style={{
                      cursor: "pointer",
                      marginTop: 7,
                      fontWeight: 400,
                      marginRight: 20,
                      color: "#333",
                    }}
                  >
                    Vital Signs Admin
                  </Link>
                  <Link
                    to="/teams"
                    style={{ marginTop: 7, fontWeighT: 400, color: "#333" }}
                  >
                    Teams Admin
                  </Link>
                </div>
                <p
                  style={{ cursor: "pointer", marginTop: 7, fontWeight: 400 }}
                  onClick={this.signOut}
                >
                  Logout
                </p>
              </div>
            </div>
            <Switch>
              <Route
                path="/teams"
                render={(props) => (
                  <div className="App-intro container">
                    <TabMenu
                      tabs={[
                        { id: "churches", display: "Teams" },
                        { id: "invite", display: "Invite Team" },
                      ]}
                      setTab={this.setTeamsTab.bind(this)}
                      tab={this.state.currentTeamsTab}
                    />

                    {this.state.currentTeamsTab === "churches" ? (
                      <TeamsChurches churches={this.state.teamChurches} />
                    ) : this.state.currentTeamsTab === "invite" ? (
                      <TeamsInvite
                        vitalSignsChurches={this.state.churches}
                        invites={this.state.teamsInvites}
                      />
                    ) : null}
                  </div>
                )}
              />
              <Route
                render={() => (
                  <div className="App-intro container">
                    <TabMenu
                      tabs={[
                        { id: "churches", display: "Churches" },
                        { id: "invite", display: "Invite" },
                        { id: "averages", display: "Averages" },
                      ]}
                      setTab={this.setTab.bind(this)}
                      tab={this.state.currentTab}
                    />

                    {this.state.currentTab === "churches" ? (
                      <Churches
                        averages={this.state.averages}
                        churches={this.state.churches}
                        customFields={this.state.customFields}
                      />
                    ) : this.state.currentTab === "invite" ? (
                      <Invite />
                    ) : (
                      <Averages />
                    )}
                  </div>
                )}
              />
            </Switch>
          </div>
        </BrowserRouter>
      );
    }

    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 100,
        }}
      >
        <h4>This user is not an admin.</h4>
        <p onClick={this.signOut}>Logout</p>
      </div>
    );
  }
}

export default App;
