import moment from "moment";
import numeral from "numeral";

numeral.nullFormat("N/A");

const getField = (responses, fieldName, campusId, exactMatch) => {
  if (campusId) {
    // only return value for campus
    return Number(responses[fieldName + "@" + campusId]);
  } else {
    // return aggregate value
    return Object.keys(responses)
      .filter(
        (field) =>
          exactMatch ? field === fieldName : field.startsWith(fieldName)
      )
      .reduce((aggregate, field) => {
        return (aggregate += Number(responses[field]));
      }, 0);
  }
};

const COVID_RELEASE_DATE = new Date(2020, 9, 2).getTime();
export const V3_RELEASE_DATE = new Date(2023, 1, 7).getTime();

const preCovidResults = [
  {
    // ATTENDANCE CHANGE
    id: "attendance-change",
    category: "reach",
    label: "Total attendance change",
    labels: {
      avg: "target",
    },
    calculate: (responses, campusId) => {
      const avg_attendance = getField(responses, "avg_attendance", campusId);
      const prev_attendance = getField(responses, "prev_attendance", campusId);
      if (prev_attendance <= 0) return 100;
      return ((avg_attendance - prev_attendance) / prev_attendance) * 100;
    },
    format: (calculation) => numeral(calculation / 100).format("0,0[.]00%"),
    messaging: (calculation) => {
      if (calculation >= 19) {
        return "Your church appears to be growing at a high rate. Have you explored this trend as a team to decipher the factors that will sustain growth?";
      }
      if (calculation < 19 && calculation >= 5) {
        return "Your attendance growth exceeds that of the average church.";
      }
      if (calculation < 5 && calculation >= 0) {
        return "Your attendance growth appears to be on par with the average church.";
      }
      if (calculation < 0) {
        return "Have you explored this trend as a team? What may be contributing to this decline?";
      }
    },
  },
  {
    // GUEST RATIO
    id: "guests-to-attendance",
    category: "reach",
    label: "Guests to attendance",
    labels: {
      avg: "target",
    },
    calculate: (responses, campusId) => {
      const avg_attendance = getField(responses, "avg_attendance", campusId);
      const guest_attendance = getField(
        responses,
        "guest_attendance",
        campusId
      );
      return guest_attendance / avg_attendance;
    },
    format: (calculation) => numeral(calculation).format("0[.][00]") + ":1",
    messaging: (calculation) => {
      if (calculation >= 1.13) {
        return "This reflects your annual number of first-time guests in proportion to your average attendance. You are drawing a very high number of guests for your church's size. However, if your church is not growing, you may have a “back door” problem.";
      }
      if (calculation < 1.13 && calculation >= 1.05) {
        return "This reflects your annual number of first-time guests in proportion to your average attendance. You appear to have a healthy “front door.” However, if your church is not growing, you may have a “back door” problem.";
      }
      if (calculation < 1.05 && calculation >= 0.95) {
        return "This reflects your annual number of first-time guests in proportion to your average attendance. You appear to be attracting an appropriate  number of guests.";
      }
      if (calculation < 0.95 && calculation >= 0.11) {
        return "This reflects your annual number of first-time guests in proportion to your average attendance. Your “front door” appears to have room for improvement.";
      }
      if (calculation < 0.11) {
        return "This reflects your annual number of first-time guests in proportion to your average attendance. You appear to be drawing a very low number of guests for your church's size. In other words, you have a “front door” problem.";
      }
    },
  },
  {
    // KIDS ATTENDANCE
    id: "percentage-kids",
    category: "reach",
    label: "Percentage of kids",
    calculate: (responses, campusId) => {
      const avg_attendance = getField(responses, "avg_attendance", campusId);
      const children_attendance = getField(
        responses,
        "children_attendance",
        campusId
      );
      return (children_attendance / avg_attendance) * 100;
    },
    format: (calculation) => numeral(calculation / 100).format("0,0[.]00%"),
    messaging: (calculation) => {
      if (calculation >= 27) {
        return "The degree to which your church is reaching young families is within the top 10% of churches. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      }
      if (calculation < 27 && calculation >= 21) {
        return "The degree to which your church is reaching young families is above average. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      }
      if (calculation < 21 && calculation >= 17) {
        return "The degree to which your church is reaching young families is on par with the average church. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      }
      if (calculation < 17) {
        return "This reflects the degree to which you are reaching young families. There is room for improvement in this area. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      }
    },
  },
  {
    // STUDENT ATTENDANCE
    id: "percentage-students",
    category: "reach",
    label: "Percentage of students",
    calculate: (responses, campusId) => {
      const avg_attendance = getField(responses, "avg_attendance", campusId);
      const student_attendance = getField(
        responses,
        "student_attendance",
        campusId
      );
      return (student_attendance / avg_attendance) * 100;
    },
    format: (calculation) => numeral(calculation / 100).format("0,0[.]00%"),
    messaging: (calculation) => {
      if (calculation >= 15) {
        return "The degree to which your church is reaching the next generation is within the top 10% of churches. Again, you may also want to compare your percentage of students in the church to the demographic data from your community.";
      }
      if (calculation < 15 && calculation >= 10) {
        return "The degree to which your church is reaching the next generation is above average. Again, you may also want to compare your percentage of students in the church to the demographic data from your community.";
      }
      if (calculation < 10 && calculation >= 9) {
        return "The degree to which your church is reaching the next generation is on par with the average church. Again, you may also want to compare your percentage of students in the church to the demographic data from your community.";
      }
      if (calculation < 9) {
        return "This reflects the degree to which you are reaching the next generation. There is room for improvement in this area. Again, you may also want to compare your percentage of students in the church to the demographic data from your community.";
      }
    },
  },
  {
    // BAPTISMS
    id: "percentage-baptisms",
    category: "reach",
    label: "Percentage of baptisms",
    calculate: (responses, campusId) => {
      const avg_attendance = getField(responses, "avg_attendance", campusId);
      const baptisms = getField(responses, "baptisms", campusId);
      return (baptisms / avg_attendance) * 100;
    },
    format: (calculation) => numeral(calculation / 100).format("0,0[.]00%"),
    messaging: (calculation) => {
      if (calculation >= 11) {
        return "The degree to which you are reaching the unchurched is within the top 10% of churches.";
      }
      if (calculation < 11 && calculation >= 7) {
        return "The degree to which you are reaching the unchurched is above average.";
      }
      if (calculation < 7 && calculation > 6) {
        return "The degree to which you are reaching the unchurched is on par with the average church.";
      }
      if (calculation <= 6) {
        return "This typically reflects the degree to which you are reaching the unchurched. There is room for improvement in this area.";
      }
    },
  },
  {
    // MEMBERSHIP
    id: "membership-count",
    category: "connection",
    label: "Membership",
    if: (responses, campusId) => {
      const total_membership = getField(
        responses,
        "total_membership",
        campusId
      );
      return total_membership > 0;
    },
    calculate: (responses, campusId) => {
      const avg_attendance = getField(responses, "avg_attendance", campusId);
      const children_attendance = getField(
        responses,
        "children_attendance",
        campusId
      );
      const total_membership = getField(
        responses,
        "total_membership",
        campusId
      );
      return (total_membership / (avg_attendance - children_attendance)) * 100;
    },
    format: (calculation) => numeral(calculation / 100).format("0,0[.]00%"),
    messaging: (calculation) => {
      if (calculation >= 255) {
        return "A very high number of people have connected to the church through membership. Though on the surface this may appear to be a win; oftentimes, this is a sign that the church has plateaued are started to decline.";
      }
      if (calculation < 255 && calculation >= 127) {
        return "A high number of people have connected to the church through membership. Though on the surface this may appear to be a win; oftentimes, this is a sign that the church has plateaued are started to decline.";
      }
      if (calculation < 127 && calculation >= 104) {
        return "An average number of people have connected to the church through membership.";
      }
      if (calculation < 104) {
        return "This reflects the degree to which people have connected to your church through membership. If membership is a part of your strategy, there is room for improvement here.";
      }
    },
  },
  {
    // STAFF RATIO
    id: "attendance-to-staff",
    category: "connection",
    label: "Attendance to staff",
    calculate: (responses, campusId) => {
      const central_full_time_staff =
        getField(responses, "central_full_time_staff", campusId) || 0;
      const central_part_time_hours =
        getField(responses, "central_part_time_hours", campusId) || 0;
      const avg_attendance = getField(responses, "avg_attendance", campusId);
      const part_time_hours = getField(responses, "part_time_hours", campusId);
      const full_time_staff = getField(responses, "full_time_staff", campusId);
      const FTE = // real full time employees
        central_part_time_hours / 40 +
        central_full_time_staff +
        part_time_hours / 40 +
        full_time_staff;
      return avg_attendance / FTE;
    },
    format: (calculation) => Math.round(calculation) + ":1",
    messaging: (calculation) => {
      if (calculation >= 69) {
        return "We encourage churches to aim for 100 to 1. You appear to be utilizing your staff well.";
      }
      if (calculation < 69) {
        return "We encourage churches to aim for 100 to 1. You appear to be overstaffed for your church's size. Heavy reliance on staff often reduces opportunities for volunteers to use their gifts in ministry.";
      }
    },
  },
  {
    // VOLUNTEERS
    id: "percentage-serving",
    category: "connection",
    label: "Percentage of adults & students serving",
    calculate: (responses, campusId) => {
      const children_attendance = getField(
        responses,
        "children_attendance",
        campusId
      );
      const avg_attendance = getField(responses, "avg_attendance", campusId);
      const volunteers = getField(responses, "volunteers", campusId);
      return (volunteers / (avg_attendance - children_attendance)) * 100;
    },
    format: (calculation) => numeral(calculation / 100).format("0,0[.]00%"),
    messaging: (calculation) => {
      if (calculation >= 71) {
        return "Your church is performing within the top 10% of churches for engaging people to utilize their gifts in ministry.";
      }
      if (calculation < 71 && calculation >= 49) {
        return "This reflects how well you engage people to utilize their gifts in ministry. You are performing at an above average level in this area.";
      }
      if (calculation < 49 && calculation >= 40) {
        return "The degree to which you engage people to utilize their gifts in ministry is on par with the average church.";
      }
      if (calculation < 40) {
        return "This reflects how well you engage people to utilize their gifts in ministry. There is room for improvement here.";
      }
    },
  },
  {
    // SMALL GROUPS
    id: "percentage-in-groups",
    category: "connection",
    label: "Percentage of adults & students in groups",
    calculate: (responses, campusId) => {
      const avg_attendance = getField(responses, "avg_attendance", campusId);
      const children_attendance = getField(
        responses,
        "children_attendance",
        campusId
      );
      const small_group_attendance = getField(
        responses,
        "small_group_attendance",
        campusId
      );
      return (
        (small_group_attendance / (avg_attendance - children_attendance)) * 100
      );
    },
    if: (responses, campusId) => {
      const small_group_attendance = getField(
        responses,
        "small_group_attendance",
        campusId
      );
      return small_group_attendance > 0;
    },
    format: (calculation) => numeral(calculation / 100).format("0,0[.]00%"),
    messaging: (calculation) => {
      if (calculation > 64) {
        return "You are engaging a high percentage of people in your church community.";
      }
      if (calculation >= 53 && calculation <= 64) {
        return "This reflects how well you are helping people engage in community. You are performing on par with other churches in this area.";
      }
      if (calculation < 53) {
        return "This reflects how well you are helping people engage in community. There is room for improvement here.";
      }
    },
  },
  {
    // PER CAPITA GIVING
    id: "per-capita-giving",
    category: "finances",
    label: "Adults & students per capita giving",
    calculate: (responses, campusId) => {
      const avg_attendance = getField(responses, "avg_attendance", campusId);
      const children_attendance = getField(
        responses,
        "children_attendance",
        campusId
      );
      const general_budget = getField(responses, "general_budget", campusId);
      return general_budget / (avg_attendance - children_attendance) / 52;
    },
    format: (calculation) => numeral(calculation).format("$0,0[.]00"),
    messaging: (calculation) => {
      if (calculation >= 67) {
        return "This can reflect vision buy-in along with spiritual maturity. Your giving levels appear to be in the top 10% of churches your size. High giving per capita may indicate that you are not attracting new people to your church.";
      }
      if (calculation < 67 && calculation >= 47) {
        return "This can reflect vision buy-in along with spiritual maturity. Your giving levels appear to be above the average of churches your size.";
      }
      if (calculation < 47 && calculation >= 39) {
        return "This can reflect vision buy-in along with spiritual maturity. Your giving levels appear to be on par with churches your size.";
      }
      if (calculation < 39) {
        return "There is opportunity to lead people towards greater generosity. This can be done in several ways, including teaching, vision-casting and storytelling.";
      }
    },
  },
  {
    // STAFF RATIO
    id: "percentage-staff-budget",
    category: "finances",
    label: "Percentage of budget spent on staff",
    labels: {
      avg: "target",
    },
    calculate: (responses, campusId) => {
      const general_budget = getField(responses, "general_budget", campusId);
      const staffing_budget = getField(responses, "staffing_budget", campusId);
      return (staffing_budget / general_budget) * 100;
    },
    format: (calculation) => numeral(calculation / 100).format("0,0[.]00%"),
    messaging: (calculation) => {
      if (calculation >= 62) {
        return "You are spending a very significant portion of your budget on staff.";
      }
      if (calculation < 61 && calculation >= 54) {
        return "You are spending an above average amount of your budget on staff.";
      }
      if (calculation < 54 && calculation >= 44) {
        return "The portion of your budget spent on staff is on par with the average church.";
      }
      if (calculation < 44 && calculation >= 37) {
        return "The portion of your budget spent on staff is below that of the average church.";
      }
      if (calculation < 37) {
        return "You appear to be spending far less than the average church on staff.";
      }
    },
  },
  {
    // CASH RESERVE
    id: "cash-reserves-weeks",
    category: "finances",
    label: "Number of weeks in cash reserves",
    labels: {
      avg: "target",
    },
    calculate: (responses, campusId) => {
      const general_budget = getField(responses, "general_budget", campusId);
      const cash_reserve = getField(responses, "cash_reserve", campusId);
      return cash_reserve / (general_budget / 52);
    },
    format: (calculation) => Math.floor(calculation),
    messaging: (calculation) => {
      if (calculation > 26) {
        return "You have more than a sufficient amount of cash reserves. If you haven't already, consider how you can invest this money to expand the church's mission and your Kingdom impact.";
      }
      if (calculation >= 8) {
        return "Your cash reserves are at a strong level.";
      }
      if (calculation < 8 && calculation >= 6) {
        return "You have an acceptable amount of cash in reserves.";
      }
      if (calculation < 6 && calculation >= 0) {
        return "Your cash reserves are at a critical level. There is a need to allot more capital to the cash reserves.";
      }
    },
  },
  {
    // DEBT RATIO
    id: "debt-to-giving",
    category: "finances",
    label: "Debt to annual giving",
    labels: {
      top: "critical",
      avg: "caution",
      bottom: "manageable",
    },
    calculate: (responses, campusId) => {
      const general_budget = getField(responses, "general_budget", campusId);
      const debt = getField(responses, "debt", campusId);
      return debt / general_budget;
    },
    format: (calculation) => numeral(calculation).format("0[.][00]") + ":1",
    messaging: (calculation) => {
      if (calculation >= 3) {
        return "We would highly caution you not to increase your current debt load.";
      }
      if (calculation < 3 && calculation >= 2) {
        return "We would caution you not to increase your current debt load.";
      }
      if (calculation < 2 && calculation >= 0.05) {
        return "Your current debt load is at a manageable level.";
      }
      if (calculation < 0.05 && calculation > 0) {
        return "Your current debt load is minimal.";
      }
      if (calculation === 0) {
        return "Your church has no current debt.";
      }
    },
  },
  {
    // ATT:SEATS
    id: "attendance-to-seats",
    category: "facilities",
    label: "Worship center attendance to seats",
    calculate: (responses, campusId) => {
      const seats = getField(responses, "seats", campusId);
      const attend = getField(responses, "avg_attendance", campusId);
      const children = getField(responses, "children_attendance", campusId) // prettier-ignore
      return (attend - children) / seats;
    },
    format: (calculation) => numeral(calculation).format("0[.][00]") + ":1",
    messaging: (calculation) => {
      if (calculation > 2.35) {
        return "You appear to be utilizing your facility very well. Have you experienced over-crowding?";
      }
      if (calculation < 2.35 && calculation >= 1.53) {
        return "You appear to be utilizing your facility well.";
      }
      if (calculation < 1.53 && calculation >= 1.25) {
        return "You have adequate room for growth in your current facility.";
      }
      if (calculation < 1.25) {
        return "You have plenty of room for growth in your current facility.";
      }
    },
  },
  {
    id: "max-seats-filled",
    category: "facilities",
    label: "Max. attendance given current parking lot size",
    calculate: (responses, campusId) => {
      const seats = getField(responses, "seats", campusId);
      const parking = getField(responses, "parking", campusId);
      return {
        seats_needed: Math.round(parking * 1.5),
        seats_available: Math.round(seats),
      };
    },
    format: (calculation) => numeral(calculation.seats_needed).format("0,0"),
    messaging: (calculation) => {
      if (calculation.seats_available > calculation.seats_needed) {
        return "You may have difficulty fully utilizing your auditorium with the current parking lot size.";
      }
      if ((calculation.seats_available = calculation.seats_needed)) {
        return "Your current parking lot size should allow for the full utilization of your auditorium.";
      }
      if (calculation.seats_available < calculation.seats_needed) {
        return "Your current parking lot size should have the ability to support a larger auditorium size.";
      }
    },
    extra: (calculation) => {
      return `Actual # of seats = ${calculation.seats_available}`;
    },
  },
  {
    id: "parking-needed",
    category: "facilities",
    label: "Parking spaces needed to maximize seating",
    calculate: (responses, campusId) => {
      const seats = getField(responses, "seats", campusId);
      const parking = getField(responses, "parking", campusId);
      return {
        parking_needed: Math.round(seats / 1.5),
        parking_available: Math.round(parking),
      };
    },
    format: (calculation) => numeral(calculation.parking_needed).format("0,0"),
    messaging: (calculation) => {
      if (calculation.parking_available < calculation.parking_needed) {
        return "You will need additional parking spots to fully utilize your auditorium.";
      }
      if ((calculation.parking_available = calculation.parking_needed)) {
        return "Your current parking lot size is sufficient for your current auditorium size.";
      }
      if (calculation.parking_available > calculation.parking_needed) {
        return "The number of parking spots you currently have should be sufficient to support future growth.";
      }
    },
    extra: (calculation) => {
      return `Actual # of spots = ${calculation.parking_available}`;
    },
  },
  {
    id: "max-3-services",
    category: "facilities",
    label: "Max. total attendance in three services",
    calculate: (responses, campusId) => {
      const attend = getField(responses, "avg_attendance", campusId);
      const seats = getField(responses, "seats", campusId);
      return {
        max_attendance: seats * 2.88,
        filled_ratio: attend / seats,
      };
    },
    format: (calculation) => numeral(calculation.max_attendance).format("0,0"),
    messaging: (calculation) => {
      if (calculation.filled_ratio >= 2.3) {
        return "Assuming the potential for 3 services in your auditorium and parking lot, you have limited room for growth.";
      }
      if (calculation.filled_ratio < 2.3 && calculation.filled_ratio >= 1.3) {
        return "Assuming the potential for 3 services in your auditorium and parking lot, you have some room for growth.";
      }
      if (calculation.filled_ratio < 1.3) {
        return "Assuming the potential for 3 services in your auditorium and parking lot, you have plenty of room for growth.";
      }
    },
  },
];

function perc(n) {
  if (isFinite(n)) {
    return numeral(n).format("0,0[.]0%");
  } else {
    return "100%"; // dividing by zero so we didn't have data for prev year
  }
}

function num(n) {
  return numeral(n).format("0,0[,]00");
}

function money(n) {
  return numeral(n).format("$0,0[,]00");
}

function sign(x) {
  if (typeof x === "string") {
    return x.charAt(0) == "-" ? x : "+" + x;
  }
  if (typeof x === "number") {
    return x < 0 ? "-" + x : "+" + x;
  }
  return x;
}

const postCovidResults = [
  {
    // BAPTISMS
    id: "percentage-baptisms",
    category: "reach",
    label: "Baptisms (% change)",
    calculate: (responses, campusId) => {
      const baptisms = Number(responses["baptisms"]);
      const prev_baptisms = Number(responses["baptisms_prev"]);
      return {
        baptisms,
        prev_baptisms,
        baptisms_percentage_change: (baptisms - prev_baptisms) / prev_baptisms,
      };
    },
    format: (calculation) => {
      return sign(perc(calculation.baptisms_percentage_change));
    },
    messaging: (calculation) => {
      return `${num(calculation.baptisms)} this year compared to ${num(
        calculation.prev_baptisms
      )} last year.`;
    },
  },
  {
    // ONLINE
    id: "online-engagement-change",
    category: "reach",
    label: "Total Online Engagement Change",
    calculate: (responses, campusId) => {
      const service_views = Number(responses["service_views"]);
      const service_views_prev = Number(responses["service_views_prev"]);

      return {
        service_views,
        service_views_prev,
        service_views_change: service_views - service_views_prev,
      };
    },
    format: (calculation) => {
      return sign(num(calculation.service_views_change));
    },
    messaging: (calculation) => {
      return `${num(calculation.service_views)} this year compared to ${num(
        calculation.service_views_prev
      )} last year.`;
    },
  },
  {
    // ATTENDANCE CHANGE
    id: "attendance-change",
    category: "reach",
    label: "Total In-Person Attendance Change",
    calculate: (responses, campusId) => {
      const avg_attendance = Number(responses["avg_attendance"]);
      const prev_attendance = Number(responses["prev_attendance"]);
      return {
        avg_attendance,
        prev_attendance,
        attendance_change: avg_attendance - prev_attendance,
      };
    },
    format: (calculation) => {
      return sign(num(calculation.attendance_change));
    },
    messaging: (calculation) => {
      return `${num(calculation.avg_attendance)} this year compared to ${num(
        calculation.prev_attendance
      )} last year.`;
    },
  },
  {
    // DB CHANGE
    id: "database-change",
    category: "reach",
    label: "New People in Database (% change)",
    calculate: (responses, campusId) => {
      const tracking_total = Number(responses["tracking_total"]);
      const tracking_new = Number(responses["tracking_new_ytd"]);
      const tracking_prev = tracking_total - tracking_new;
      return {
        tracking_total,
        tracking_prev,
        attendance_change: (tracking_total - tracking_prev) / tracking_prev,
      };
    },
    format: (calculation) => {
      return sign(perc(calculation.attendance_change));
    },
    messaging: (calculation) => {
      return `${num(calculation.tracking_total)} this year compared to ${num(
        calculation.tracking_prev
      )} last year.`;
    },
  },
  {
    // EMAIL SUSBCRIBERS
    id: "email-subscribers-percentage",
    category: "reach",
    label: "Email Subscribers Change",
    calculate: (responses, campusId) => {
      const email_subscribers = Number(responses["email_subscribers"]);
      const email_subscribers_prev = Number(
        responses["email_subscribers_prev"]
      );
      return {
        email_subscribers,
        email_subscribers_prev,
        email_subscribers_change: email_subscribers - email_subscribers_prev,
      };
    },
    format: (calculation) => {
      return sign(num(calculation.email_subscribers_change));
    },
    messaging: (calculation) => {
      return `${num(calculation.email_subscribers)} this year compared to ${num(
        calculation.email_subscribers_prev
      )} last year.`;
    },
  },
  {
    // KIDS ATTENDANCE
    id: "percentage-kids",
    category: "reach",
    label: "Kids (% change)",
    calculate: (responses, campusId) => {
      const children_attendance = Number(responses["children_attendance"]);
      const children_attendance_prev = Number(
        responses["children_attendance_prev"]
      );
      const children_attendance_percentage_change =
        (children_attendance - children_attendance_prev) /
        children_attendance_prev;
      return {
        children_attendance,
        children_attendance_prev,
        children_attendance_percentage_change,
      };
    },
    format: (calculation) => {
      return sign(perc(calculation.children_attendance_percentage_change));
    },
    messaging: (calculation) => {
      return `${num(
        calculation.children_attendance
      )} this year compared to ${num(
        calculation.children_attendance_prev
      )} last year.`;
    },
  },
  {
    // STUDENT ATTENDANCE
    id: "percentage-students",
    category: "reach",
    label: "Students (% change)",
    calculate: (responses, campusId) => {
      const student_attendance = Number(responses["student_attendance"]);
      const student_attendance_prev = Number(
        responses["student_attendance_prev"]
      );
      return {
        student_attendance,
        student_attendance_prev,
        student_attendance_percentage_change:
          (student_attendance - student_attendance_prev) /
          student_attendance_prev,
      };
    },
    format: (calculation) => {
      return sign(perc(calculation.student_attendance_percentage_change));
    },
    messaging: (calculation) => {
      return `${num(
        calculation.student_attendance
      )} this year compared to ${num(
        calculation.student_attendance_prev
      )} last year.`;
    },
  },
  {
    // VOLUNTEERS
    id: "percentage-serving",
    category: "connection",
    label: "Adults & Students Serving (% change)",
    calculate: (responses, campusId) => {
      const volunteers = Number(responses["volunteers"]);
      const volunteers_prev = Number(responses["volunteers_prev"]);
      return {
        volunteers,
        volunteers_prev,
        volunteers_percenage_change:
          (volunteers - volunteers_prev) / volunteers_prev,
      };
    },
    format: (calculation) => {
      return sign(perc(calculation.volunteers_percenage_change));
    },
    messaging: (calculation) => {
      return `${num(calculation.volunteers)} this year compared to ${num(
        calculation.volunteers_prev
      )} last year.`;
    },
  },
  {
    // SMALL GROUPS
    id: "percentage-in-groups",
    category: "connection",
    label: "Adults & Students in Groups (% change)",
    calculate: (responses, campusId) => {
      const small_group_attendance = Number(
        responses["small_group_attendance"]
      );
      const small_group_attendance_prev = Number(
        responses["small_group_attendance_prev"]
      );
      return {
        small_group_attendance,
        small_group_attendance_prev,
        small_group_attendance_percentage_change:
          (small_group_attendance - small_group_attendance_prev) /
          small_group_attendance_prev,
      };
    },
    if: (responses, campusId) => {
      const small_group_attendance = Number(
        responses["small_group_attendance"]
      );
      const small_group_attendance_prev = Number(
        responses["small_group_attendance_prev"]
      );
      return small_group_attendance > 0 && small_group_attendance_prev > 0;
    },
    format: (calculation) => {
      return sign(perc(calculation.small_group_attendance_percentage_change));
    },
    messaging: (calculation) => {
      return `${num(
        calculation.small_group_attendance
      )} this year compared to ${num(
        calculation.small_group_attendance_prev
      )} last year.`;
    },
  },
  {
    // MEMBERSHIP
    id: "membership-count",
    category: "connection",
    label: "Membership",
    if: (responses, campusId) => {
      const total_membership = Number(responses["total_membership"]);
      return total_membership > 0;
    },
    calculate: (responses, campusId) => {
      const total_membership = Number(responses["total_membership"]);
      const total_membership_prev = Number(responses["total_membership_prev"]);
      return {
        total_membership,
        total_membership_prev,
        total_membership_percentage_change:
          (total_membership - total_membership_prev) / total_membership_prev,
      };
    },
    format: (calculation) => {
      return sign(perc(calculation.total_membership_percentage_change));
    },
    messaging: (calculation) => {
      return `${num(calculation.total_membership)} this year compared to ${num(
        calculation.total_membership_prev
      )} last year.`;
    },
  },
  {
    // STAFF RATIO
    id: "percentage-staff-budget",
    category: "finances",
    label: "% Budget Spent on Staff",
    calculate: (responses, campusId) => {
      const general_budget = Number(responses["general_budget"]);
      const staffing_budget = Number(responses["staffing_budget"]);
      return {
        general_budget,
        staffing_budget,
        stuff_budget_percentage: staffing_budget / general_budget,
      };
    },
    format: (calculation) => {
      return sign(perc(calculation.stuff_budget_percentage));
    },
    messaging: (calculation) => {
      const n = calculation.stuff_budget_percentage * 100;
      if (n >= 62) {
        return "You are spending a very significant portion of your budget on staff.";
      }
      if (n < 61 && n >= 54) {
        return "You are spending an above average amount of your budget on staff.";
      }
      if (n < 54 && n >= 44) {
        return "The portion of your budget spent on staff is on par with the average church.";
      }
      if (n < 44 && n >= 37) {
        return "The portion of your budget spent on staff is below that of the average church.";
      }
      if (n < 37) {
        return "You appear to be spending far less than the average church on staff.";
      }
    },
  },
  {
    // GIVING UNITS
    id: "per-capita-giving",
    category: "finances",
    label: "Giving Units (% change)",
    calculate: (responses, campusId) => {
      const giving_units = Number(responses["giving_units"]);
      const giving_units_prev = Number(responses["giving_units_prev"]);
      return {
        giving_units,
        giving_units_prev,
        giving_units_percentage_change:
          (giving_units - giving_units_prev) / giving_units_prev,
      };
    },
    format: (calculation) => {
      return sign(perc(calculation.giving_units_percentage_change));
    },
    messaging: (calculation) => {
      return `${num(calculation.giving_units)} this year compared to ${num(
        calculation.giving_units_prev
      )} last year.`;
    },
  },
  {
    // CASH RESERVE
    id: "cash-reserves-weeks",
    category: "finances",
    label: "# Weeks in Cash Reserves",
    labels: {
      avg: "target",
    },
    calculate: (responses, campusId) => {
      const cash_reserve = Number(responses["cash_reserve"]);
      const general_budget = Number(responses["general_budget"]);
      return {
        cash_reserve,
        general_budget,
        weekly_cash_reserve: cash_reserve / (general_budget / 52),
      };
    },
    format: (calculation) => {
      return Math.floor(calculation.weekly_cash_reserve);
    },
    messaging: (calculation) => {
      if (calculation.weekly_cash_reserve >= 8) {
        return "Your cash reserves are at a strong level.";
      }
      if (
        calculation.weekly_cash_reserve < 8 &&
        calculation.weekly_cash_reserve >= 6
      ) {
        return "You have an acceptable amount of cash in reserves.";
      }
      if (calculation.weekly_cash_reserve < 6) {
        return "Your cash reserves are at a critical level. There is a need to allot more capital to the cash reserves.";
      }
    },
  },
  {
    // DEBT RATIO
    id: "debt-to-giving",
    category: "finances",
    label: "Debt to Annual Giving",
    calculate: (responses, campusId) => {
      const general_budget = Number(responses["general_budget"]);
      const debt = Number(responses["debt"]);
      return {
        general_budget,
        debt,
        debt_to_giving: debt / general_budget,
      };
    },
    format: (calculation) => {
      return numeral(calculation.debt_to_giving).format("0[.][00]") + ":1";
    },
    messaging: (calculation) => {
      if (calculation.debt_to_giving >= 3) {
        return "We would highly caution you not to increase your current debt load.";
      }
      if (calculation.debt_to_giving < 3 && calculation.debt_to_giving >= 2) {
        return "We would caution you not to increase your current debt load.";
      }
      if (
        calculation.debt_to_giving < 2 &&
        calculation.debt_to_giving >= 0.05
      ) {
        return "Your current debt load is at a manageable level.";
      }
      if (calculation.debt_to_giving < 0.05 && calculation.debt_to_giving > 0) {
        return "Your current debt load is minimal.";
      }
      if (calculation.debt_to_giving === 0) {
        return "Your church has no current debt.";
      }
    },
  },
  {
    id: "max-seats-filled",
    category: "facilities",
    label: "Max Attendance Given Current Parking Lot Size",
    calculate: (responses, campusId) => {
      const seats = Number(responses["seats"]);
      const parking = Number(responses["parking"]);
      return {
        seats,
        parking,
        seats_needed: Math.round(parking * 1.5),
        seats_available: Math.round(seats),
      };
    },
    format: (calculation) => {
      return num(calculation.seats_needed);
    },
    messaging: (calculation) => {
      return `Estimated from ${num(calculation.parking)} parking spots.`;
    },
    extra: (calculation) => {
      return `Actual # of seats = ${num(calculation.seats_available)}`;
    },
  },
  {
    id: "parking-needed",
    category: "facilities",
    label: "Parking Spaces Needed to Maximize Seating",
    calculate: (responses, campusId) => {
      const seats = Number(responses["seats"]);
      const parking = Number(responses["parking"]);
      return {
        seats,
        parking,
        parking_needed: Math.round(seats / 1.5),
        parking_available: Math.round(parking),
      };
    },
    format: (calculation) => {
      return num(calculation.parking_needed);
    },
    messaging: (calculation) => {
      return `Estimated from ${num(calculation.seats)} available seats.`;
    },
    extra: (calculation) => {
      return `Actual # of spots = ${num(calculation.parking_available)}`;
    },
  },
  {
    id: "max-3-services",
    category: "facilities",
    label: "Max Total Attendance in Three Services",
    calculate: (responses, campusId) => {
      const attend = Number(responses["avg_attendance"]);
      const seats = Number(responses["seats"]);
      return {
        seats,
        attend,
        max_attendance: seats * 2.88,
        filled_ratio: attend / seats,
      };
    },
    format: (calculation) => {
      return num(calculation.max_attendance);
    },
    messaging: (calculation) => {
      return `Estimated from ${num(calculation.seats)} available seats.`;
    },
  },
  {
    id: "church-denomination",
    category: "footer",
    label: "Church Denomination",
    calculate: (responses) => responses["denomination"],
    format: (calculation) => "",
    messaging: (calculation) => "",
    extra: (calculation) => calculation,
  },
  {
    id: "elder-board-members",
    category: "footer",
    label: "Number of People on Board or Elder Team",
    calculate: (responses) => responses["elder_board_members"],
    format: (calculation) => {
      return num(calculation);
    },
    messaging: (calculation) => "",
  },
  {
    id: "additional-boards",
    category: "footer",
    label: "Number of Other Boards and Committees",
    calculate: (responses) => responses["additional_boards"],
    format: (calculation) => {
      return num(calculation);
    },
    messaging: (calculation) => "",
  },
  {
    id: "multisite",
    category: "footer",
    label: "Multisite Church",
    if: (responses) => responses["multisite"] == "Yes",
    calculate: (responses) => responses["locations_count"] || 1,
    format: (calculation) => {
      return num(calculation);
    },
    messaging: (calculation) => "",
    extra: (calculation) => "Locations",
  },
  {
    id: "service-style",
    category: "footer",
    label: "Style of Services",
    calculate: (responses) => responses["service_style"],
    format: (calculation) => "",
    messaging: (calculation) => "",
    extra: (calculation) => calculation,
  },
  {
    id: "staffing-fte",
    category: "footer",
    label: "Staffing Full-Time Employees",
    calculate: (responses, campusId) => {
      const full_time_staff = Number(responses["full_time_staff"]);
      const part_time_hours = Number(responses["part_time_hours"]);
      return {
        full_time_staff,
        part_time_hours,
        staffing_fte: full_time_staff + part_time_hours / 40,
      };
    },
    format: (calculation) => {
      return num(calculation.staffing_fte);
    },
    messaging: (calculation) =>
      `${num(calculation.full_time_staff)} full-time staff plus ${num(
        calculation.part_time_hours
      )} part-time hours`,
  },
  {
    id: "volunteer-leaders",
    category: "footer",
    label: "Volunteer Leaders",
    calculate: (responses) => responses["volunteer_leaders"],
    format: (calculation) => {
      return num(calculation);
    },
    messaging: (calculation) => "",
  },
];

const v3CovidResults = [
  {
    // ATTENDANCE CHANGE
    id: "attendance-change",
    category: "reach",
    label: "Total In-Person Attendance Change",
    calculate: (responses, campusId) => {
      const avg_attendance = Number(responses["avg_attendance"]);
      const prev_attendance = Number(responses["prev_attendance"]);
      return ((avg_attendance - prev_attendance) / prev_attendance) * 100;
      // return {
      //   avg_attendance,
      //   prev_attendance,
      //   attendance_change: avg_attendance - prev_attendance,
      // };
    },
    format: (calculation) => {
      return sign(perc(calculation / 100));
    },
    benchmark: "5% - 10%",
    messaging: (calculation) => {
      if (calculation >= 19) {
        return "Your church appears to be growing at a high rate. Have you explored this trend as a team to decipher the factors that will sustain growth? ";
      } else if (calculation >= 5) {
        return "Your attendance growth exceeds that of the average church.";
      } else if (calculation >= 0) {
        return "Your attendance growth appears to be on par with the average church.";
      } else {
        return " Have you explored this trend as a team? What may be contributing to this decline?";
      }
    },
  },
  {
    // ONLINE
    id: "online-engagement-change",
    category: "reach",
    label: "Online Engagement Change",
    benchmark: "5-10%",

    calculate: (responses, campusId) => {
      const service_views = Number(responses["service_views"]);
      const service_views_prev = Number(responses["service_views_prev"]);
      const avg_attendance = Number(responses["avg_attendance"]);

      //((Q14-Q15)/Q15)*100
      return ((service_views - service_views_prev) / service_views_prev) * 100;
    },
    format: (calculation) => {
      return sign(perc(calculation / 100));
    },
    messaging: (calculation) => {
      if (calculation >= 19) {
        return "Your online engagement appears to be growing at a high rate. Have you explored this trend as a team to decipher the factors that will sustain growth?";
      } else if (calculation >= 5) {
        return " Your online engagement growth exceeds that of the average church.";
      } else if (calculation >= 0) {
        return "Your online engagement growth appears to be on par with the average church.";
      } else {
        return "Have you explored this trend as a team? What may be contributing to this decline?";
      }
    },
  },

  {
    // DB CHANGE
    id: "database-change",
    category: "reach",
    label: "New People in Database Ratio",
    benchmark: "2:1",
    calculate: (responses, campusId) => {
      const avg_attendance = Number(responses["avg_attendance"]);
      const tracking_new = Number(responses["tracking_new_ytd"]);
      // const tracking_prev = tracking_total - tracking_new;

      return tracking_new / avg_attendance;
      // return {
      //   tracking_total,
      //   tracking_prev,
      //   attendance_change: (tracking_total - tracking_prev) / tracking_prev,
      // };
    },
    format: (calculation) => {
      return `${Math.round(calculation * 10) / 10}:1`;
    },
    messaging: (calculation) => {
      // return `${num(calculation.tracking_total)} this year compared to ${num(
      //   calculation.tracking_prev
      // )} last year.`;
    },
  },

  {
    // BAPTISMS
    id: "percentage-baptisms",
    category: "reach",
    label: "Percentage of Baptisms",
    benchmark: "5%",
    calculate: (responses, campusId) => {
      const baptisms = Number(responses["baptisms"]);
      const attendance = Number(responses["avg_attendance"]);

      return (baptisms / attendance) * 100;

      // return {
      //   baptisms,
      //   prev_baptisms,
      //   baptisms_percentage_change: (baptisms - prev_baptisms) / prev_baptisms,
      // };
    },
    format: (calculation) => {
      return perc(calculation / 100);
    },
    messaging: (calculation) => {
      // return `${num(calculation.baptisms)} this year compared to ${num(
      //   calculation.prev_baptisms
      // )} last year.`;

      if (calculation >= 11) {
        return "The degree to which you are reaching the unchurched is within the top 10% of churches.";
      } else if (calculation >= 7) {
        return "The degree to which you are reaching the unchurched is above average.";
      } else if (calculation >= 5) {
        return "The degree to which you are reaching the unchurched is on par with the average church.";
      } else {
        return "This typically reflects the degree to which you are reaching the unchurched. There is room for improvement in this area.";
      }
    },
  },
  // {
  //   // EMAIL SUSBCRIBERS
  //   id: "email-subscribers-percentage",
  //   category: "reach",
  //   label: "Email Subscribers Change",
  //   calculate: (responses, campusId) => {
  //     const email_subscribers = Number(responses["email_subscribers"]);
  //     const email_subscribers_prev = Number(
  //       responses["email_subscribers_prev"]
  //     );
  //     return {
  //       email_subscribers,
  //       email_subscribers_prev,
  //       email_subscribers_change: email_subscribers - email_subscribers_prev,
  //     };
  //   },
  //   format: (calculation) => {
  //     return sign(num(calculation.email_subscribers_change));
  //   },
  //   messaging: (calculation) => {
  //     return `${num(calculation.email_subscribers)} this year compared to ${num(
  //       calculation.email_subscribers_prev
  //     )} last year.`;
  //   },
  // },
  {
    // KIDS ATTENDANCE
    id: "percentage-kids",
    category: "reach",
    label: "Percentage of Kids",
    benchmark: "20%",
    calculate: (responses, campusId) => {
      const children_attendance = Number(responses["children_attendance"]);
      const avg_attendance = Number(responses["avg_attendance"]);

      return (children_attendance / avg_attendance) * 100;
    },
    format: (calculation) => {
      return perc(calculation / 100);
    },
    messaging: (calculation) => {
      if (calculation >= 27) {
        return "The degree to which your church is reaching young families is within the top 10% of churches. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      } else if (calculation >= 21) {
        return " The degree to which your church is reaching young families is above average. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      } else if (calculation >= 17) {
        return "The degree to which your church is reaching young families is on par with the average church. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      } else {
        return "This reflects the degree to which you are reaching young families. There is room for improvement in this area. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      }
    },
  },

  {
    // KIDS ATTENDANCE
    id: "kids-checkins",
    category: "reach",
    label: "Number of First Time Kids Check-Ins",
    benchmark: "1:1",
    calculate: (responses, campusId) => {
      const firstTimeCheckins = getField(
        responses,
        "first_time_kids_checkins",
        campusId
      );
      const checkins = getField(responses, "children_attendance", campusId);
      return (firstTimeCheckins / checkins) * 100;
    },
    format: (calculation) => `${Math.round(calculation / 10) / 10}:1`,
    messaging: (calculation) => {
      return "";
      // if (calculation >= 27) {
      //   return "The degree to which your church is reaching young families is within the top 10% of churches. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      // }
      // if (calculation < 27 && calculation >= 21) {
      //   return "The degree to which your church is reaching young families is above average. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      // }
      // if (calculation < 21 && calculation >= 17) {
      //   return "The degree to which your church is reaching young families is on par with the average church. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      // }
      // if (calculation < 17) {
      //   return "This reflects the degree to which you are reaching young families. There is room for improvement in this area. You may also want to compare your percentage of kids in the church to the demographic data from your community.";
      // }
    },
  },
  {
    // STUDENT ATTENDANCE
    id: "percentage-students",
    category: "reach",
    label: "Percentage of Students",
    benchmark: "10%",
    calculate: (responses, campusId) => {
      const student_attendance = Number(responses["student_attendance"]);
      const avg_attendance = Number(responses["avg_attendance"]);

      return (student_attendance / avg_attendance) * 100;
    },
    format: (calculation) => {
      return perc(calculation / 100);
    },
    messaging: (calculation) => {
      if (calculation >= 15) {
        return "The degree to which your church is reaching the next generation is within the top 10% of churches. Again, you may also want to compare your percentage of students in the church to the demographic data from your community.";
      } else if (calculation >= 10) {
        return "The degree to which your church is reaching the next generation is above average. Again, you may also want to compare your percentage of students in the church to the demographic data from your community.";
      } else if (calculation >= 9) {
        return "The degree to which your church is reaching the next generation is on par with the average church. Again, you may also want to compare your percentage of students in the church to the demographic data from your community.";
      } else {
        return "This reflects the degree to which you are reaching the next generation. There is room for improvement in this area. Again, you may also want to compare your percentage of students in the church to the demographic data from your community.";
      }
    },
  },
  {
    // VOLUNTEERS
    id: "percentage-serving",
    category: "connection",
    label: "Percentage of Adults & Students Serving",
    benchmark: "50%",
    calculate: (responses, campusId) => {
      const volunteers = Number(responses["volunteers"]);
      const avg_attendance = Number(responses["avg_attendance"]);
      const children_attendance = Number(responses["children_attendance"]);

      return (volunteers / (avg_attendance - children_attendance)) * 100;
      //(Q27/(Q12-Q16))*100
    },
    format: (calculation) => {
      return perc(calculation / 100);
    },
    messaging: (calculation) => {
      if (calculation >= 71) {
        return "Your church is performing within the top 10% of churches for engaging people to utilize their gifts in ministry.";
      } else if (calculation >= 50) {
        return "This reflects how well you engage people to utilize their gifts in ministry. You are performing at an above average level in this area.";
      } else if (calculation >= 40) {
        return "The degree to which you engage people to utilize their gifts in ministry is on par with the average church.";
      } else {
        return "This reflects how well you engage people to utilize their gifts in ministry. There is room for improvement here.";
      }
    },
  },
  {
    // SMALL GROUPS
    id: "percentage-in-groups",
    category: "connection",
    label: "Percentage of Adults & Students In Groups",
    benchmark: "55%",
    calculate: (responses, campusId) => {
      const small_group_attendance = Number(
        responses["small_group_attendance"]
      );
      const avg_attendance = Number(responses["avg_attendance"]);
      const children_attendance = Number(responses["children_attendance"]);

      return (
        (small_group_attendance / (avg_attendance - children_attendance)) * 100
      );
    },
    // if: (responses, campusId) => {
    //   const small_group_attendance = Number(
    //     responses["small_group_attendance"]
    //   );
    //   const small_group_attendance_prev = Number(
    //     responses["small_group_attendance_prev"]
    //   );
    //   return small_group_attendance > 0 && small_group_attendance_prev > 0;
    // },
    format: (calculation) => {
      return perc(calculation / 100);
    },
    messaging: (calculation) => {
      if (calculation > 64) {
        return "You are engaging a high percentage of people in your church community.";
      } else if (calculation >= 55) {
        return "This reflects how well you are helping people engage in community. You are performing on par with other churches in this area.";
      } else {
        return "This reflects how well you are helping people engage in community. There is room for improvement here.";
      }
    },
  },
  {
    // Attendance to Staff
    id: "attendance-to-staff",
    category: "ministry-staffing",
    label: "Attendance to Staff",
    benchmark: "75:1",
    // if: (responses, campusId) => {
    //   const total_membership = Number(responses["total_membership"]);
    //   return total_membership > 0;
    // },
    calculate: (responses, campusId) => {
      const avg_attendance = Number(responses["avg_attendance"]);
      const part_time_hours = Number(responses["part_time_hours"]);
      const full_time_staff = Number(responses["full_time_staff"]);

      //Q12 / (Q33 / 40 + Q31);

      return avg_attendance / (part_time_hours / 40 + full_time_staff);
    },
    format: (calculation) => {
      return `${Math.round(calculation)}:1`;
    },
    messaging: (calculation) => {
      if (calculation >= 75) {
        return "We encourage churches to aim for 75 to 1. You appear to be utilizing your staff well";
      } else {
        return "We encourage churches to aim for 75 to 1. You appear to be overstaffed for your church's size. Heavy reliance on staff often reduces opportunities for volunteers to use their gifts in ministry.";
      }
    },
  },
  {
    // Attendance to Volunteer Leaders
    id: "attendance-to-volunteer-leaders",
    category: "ministry-staffing",
    label: "Attendance to Volunteer Leaders",
    benchmark: "10:1",
    // if: (responses, campusId) => {
    //   const total_membership = Number(responses["total_membership"]);
    //   return total_membership > 0;
    // },
    calculate: (responses, campusId) => {
      const avg_attendance = Number(responses["avg_attendance"]);
      const volunteer_leaders = Number(responses["volunteer_leaders"]);

      return avg_attendance / volunteer_leaders;
    },
    format: (calculation) => {
      return `${Math.round(calculation)}:1`;
    },
    messaging: (calculation) => {
      if (calculation >= 10) {
        return "You appear not to have enough higher-level volunteers engaged in your ministry. Are there roles your staff ministry leaders could delegate to higher-level volunteer leaders?";
      } else if (calculation >= 7) {
        return "You appear to effectively engage volunteer leaders in your ministries.";
      } else {
        return "You appear to be engaging a significant number of volunteer leaders in your ministry. When this is in place, teams have a smaller span of care and are living out the Ephesians 4 model of giving ministry away to the body.";
      }
    },
  },

  // {
  //   // MEMBERSHIP
  //   id: "membership-count",
  //   category: "connection",
  //   label: "Membership",
  //   if: (responses, campusId) => {
  //     const total_membership = Number(responses["total_membership"]);
  //     return total_membership > 0;
  //   },
  //   calculate: (responses, campusId) => {
  //     const total_membership = Number(responses["total_membership"]);
  //     const total_membership_prev = Number(responses["total_membership_prev"]);
  //     return {
  //       total_membership,
  //       total_membership_prev,
  //       total_membership_percentage_change:
  //         (total_membership - total_membership_prev) / total_membership_prev,
  //     };
  //   },
  //   format: (calculation) => {
  //     return sign(perc(calculation.total_membership_percentage_change));
  //   },
  //   messaging: (calculation) => {
  //     return `${num(calculation.total_membership)} this year compared to ${num(
  //       calculation.total_membership_prev
  //     )} last year.`;
  //   },
  // },

  // {
  //   // GIVING UNITS
  //   id: "per-capita-giving",
  //   category: "finances",
  //   label: "Giving Units (% change)",
  //   calculate: (responses, campusId) => {
  //     const giving_units = Number(responses["giving_units"]);
  //     const giving_units_prev = Number(responses["giving_units_prev"]);
  //     return {
  //       giving_units,
  //       giving_units_prev,
  //       giving_units_percentage_change:
  //         (giving_units - giving_units_prev) / giving_units_prev,
  //     };
  //   },
  //   format: (calculation) => {
  //     return sign(perc(calculation.giving_units_percentage_change));
  //   },
  //   messaging: (calculation) => {
  //     return `${num(calculation.giving_units)} this year compared to ${num(
  //       calculation.giving_units_prev
  //     )} last year.`;
  //   },
  // },

  {
    // PER CAPITA GIVING
    id: "per-capita-giving",
    category: "finances",
    label: "Adults & students per capita giving",
    benchmark: "$60",
    calculate: (responses, campusId) => {
      const avg_attendance = getField(
        responses,
        "avg_attendance",
        campusId,
        true
      );
      const children_attendance = getField(
        responses,
        "children_attendance",
        campusId,
        true
      );
      const general_budget = getField(
        responses,
        "general_budget",
        campusId,
        true
      );

      return general_budget / (avg_attendance - children_attendance) / 52;
    },
    format: (calculation) => numeral(calculation).format("$0,0[.]00"),
    messaging: (calculation) => {
      if (calculation >= 67) {
        return "This can reflect vision buy-in along with spiritual maturity. Your giving levels appear to be in the top 10% of churches your size. High giving per capita may indicate that you are not attracting new people to your church.";
      }
      if (calculation < 67 && calculation >= 47) {
        return "This can reflect vision buy-in along with spiritual maturity. Your giving levels appear to be above the average of churches your size.";
      }
      if (calculation < 47 && calculation >= 39) {
        return "This can reflect vision buy-in along with spiritual maturity. Your giving levels appear to be on par with churches your size.";
      }
      if (calculation < 39) {
        return "There is opportunity to lead people towards greater generosity. This can be done in several ways, including teaching, vision-casting and storytelling.";
      }
    },
  },
  {
    // STAFF RATIO
    id: "percentage-staff-budget",
    category: "finances",
    label: "% Budget Spent on Staff",
    benchmark: "50%",
    calculate: (responses, campusId) => {
      const general_budget = Number(responses["general_budget"]);
      const staffing_budget = Number(responses["staffing_budget"]);
      return {
        general_budget,
        staffing_budget,
        stuff_budget_percentage: staffing_budget / general_budget,
      };
    },
    format: (calculation) => {
      return perc(calculation.stuff_budget_percentage);
    },
    messaging: (calculation) => {
      const n = calculation.stuff_budget_percentage * 100;
      if (n >= 62) {
        return "You are spending a very significant portion of your budget on staff.";
      }
      if (n < 61 && n >= 54) {
        return "You are spending an above average amount of your budget on staff.";
      }
      if (n < 54 && n >= 44) {
        return "The portion of your budget spent on staff is on par with the average church.";
      }
      if (n < 44 && n >= 37) {
        return "The portion of your budget spent on staff is below that of the average church.";
      }
      if (n < 37) {
        return "You appear to be spending far less than the average church on staff.";
      }
    },
  },

  {
    // CASH RESERVE
    id: "cash-reserves-weeks",
    category: "finances",
    label: "# Weeks in Cash Reserves",
    benchmark: "8-12",
    labels: {
      avg: "target",
    },
    calculate: (responses, campusId) => {
      const cash_reserve = Number(responses["cash_reserve"]);
      const general_budget = Number(responses["general_budget"]);
      return {
        cash_reserve,
        general_budget,
        weekly_cash_reserve: cash_reserve / (general_budget / 52),
      };
    },
    format: (calculation) => {
      return Math.floor(calculation.weekly_cash_reserve);
    },
    messaging: (calculation) => {
      if (calculation.weekly_cash_reserve >= 8) {
        return "Your cash reserves are at a strong level.";
      }
      if (
        calculation.weekly_cash_reserve < 8 &&
        calculation.weekly_cash_reserve >= 6
      ) {
        return "You have an acceptable amount of cash in reserves.";
      }
      if (calculation.weekly_cash_reserve < 6) {
        return "Your cash reserves are at a critical level. There is a need to allot more capital to the cash reserves.";
      }
    },
  },
  {
    // DEBT RATIO
    id: "debt-to-giving",
    category: "finances",
    label: "Debt to Annual Giving",
    // benchmark: "8-12",
    calculate: (responses, campusId) => {
      const general_budget = Number(responses["general_budget"]);
      const debt = Number(responses["debt"]);
      return {
        general_budget,
        debt,
        debt_to_giving: debt / general_budget,
      };
    },
    format: (calculation) => {
      return numeral(calculation.debt_to_giving).format("0[.][00]") + ":1";
    },
    messaging: (calculation) => {
      if (calculation.debt_to_giving >= 3) {
        return "We would highly caution you not to increase your current debt load.";
      }
      if (calculation.debt_to_giving < 3 && calculation.debt_to_giving >= 2) {
        return "We would caution you not to increase your current debt load.";
      }
      if (
        calculation.debt_to_giving < 2 &&
        calculation.debt_to_giving >= 0.05
      ) {
        return "Your current debt load is at a manageable level.";
      }
      if (calculation.debt_to_giving < 0.05 && calculation.debt_to_giving > 0) {
        return "Your current debt load is minimal.";
      }
      if (calculation.debt_to_giving === 0) {
        return "Your church has no current debt.";
      }
    },
  },
  // {
  //   // Worship Center Attendance to Seats
  //   id: "attendance-to-seats",
  //   category: "finances",
  //   label: "Worship Center Attendance to Seats",
  //   // benchmark: "8-12",
  //   calculate: (responses, campusId) => {
  //     //(Q12 - Q16) / Q41;

  //     const avg_attendance = Number(responses["avg_attendance"]);
  //     const children_attendance = Number(responses["children_attendance"]);
  //     const seats = Number(responses["seats"]);
  //     return (avg_attendance - children_attendance) / seats;
  //   },
  //   format: (calculation) => {
  //     return numeral(calculation).format("0[.][00]") + ":1";
  //   },
  //   messaging: (calculation) => {
  //     if (calculation >= 2.35) {
  //       return "You appear to be utilizing your facility very well. Have you experienced overcrowding?";
  //     } else if (calculation >= 1.53) {
  //       return "You appear to be utilizing your facility well.";
  //     } else if (calculation >= 1.25) {
  //       return "You have adequate room for growth in your current facility.";
  //     } else {
  //       return "You have plenty of room for growth in your current facility.";
  //     }
  //   },
  // },
  // {
  //   id: "max-seats-filled",
  //   category: "finances",
  //   label: "Max Attendance Given Current Parking Lot Size",
  //   calculate: (responses, campusId) => {
  //     const seats = Number(responses["seats"]);
  //     const parking = Number(responses["parking"]);
  //     return {
  //       seats,
  //       parking,
  //       seats_needed: Math.round(parking * 1.5),
  //       seats_available: Math.round(seats),
  //     };
  //   },
  //   format: (calculation) => {
  //     return num(calculation.seats_needed);
  //   },
  //   messaging: (calculation) => {
  //     return `Estimated from ${num(calculation.parking)} parking spots.`;
  //   },
  //   extra: (calculation) => {
  //     return `Actual # of seats = ${num(calculation.seats_available)}`;
  //   },
  // },
  {
    id: "parking-space-margin",
    category: "finances",
    label: "Parking Space Margin",
    calculate: (responses, campusId) => {
      const seats = Number(responses["seats"]);
      const parking = Number(responses["parking"]);

      return parking - Math.round(seats / 1.5);
    },
    format: (calculation) => {
      return num(calculation);
    },
    messaging: (calculation) => {
      if (calculation > 0) {
        return "The number of parking spots exceeds your current need compared to the seating capacity available";
      } else if (calculation === 0) {
        return "The number of parking spots equals your current need compared to the seating capacity available.";
      } else {
        return "The number of parking spots is insufficient for your current need compared to the seating capacity available.";
      }
    },
    // extra: (calculation) => {
    //   return `Actual # of spots = ${num(calculation.parking_available)}`;
    // },
  },
  // {
  //   id: "max-3-services",
  //   category: "facilities",
  //   label: "Max Total Attendance in Three Services",
  //   calculate: (responses, campusId) => {
  //     const attend = Number(responses["avg_attendance"]);
  //     const seats = Number(responses["seats"]);
  //     return {
  //       seats,
  //       attend,
  //       max_attendance: seats * 2.88,
  //       filled_ratio: attend / seats,
  //     };
  //   },
  //   format: (calculation) => {
  //     return num(calculation.max_attendance);
  //   },
  //   messaging: (calculation) => {
  //     return `Estimated from ${num(calculation.seats)} available seats.`;
  //   },
  // },

  // Smaller stats
  {
    id: "church-denomination",
    category: "footer",
    label: "Church Denomination",
    calculate: (responses) => responses["denomination"],
    format: (calculation) => "",
    messaging: (calculation) => "",
    extra: (calculation) => calculation,
  },
  {
    id: "elder-board-members",
    category: "footer",
    label: "Number of People on Board or Elder Team",
    calculate: (responses) => responses["elder_board_members"],
    format: (calculation) => {
      return num(calculation);
    },
    messaging: (calculation) => "",
  },
  {
    id: "additional-boards",
    category: "footer",
    label: "Number of Other Boards and Committees",
    calculate: (responses) => responses["additional_boards"],
    format: (calculation) => {
      return num(calculation);
    },
    messaging: (calculation) => "",
  },
  {
    id: "multisite",
    category: "footer",
    label: "Multisite Church",
    if: (responses) => responses["multisite"] == "Yes",
    calculate: (responses) => responses["locations_count"] || 1,
    format: (calculation) => {
      return num(calculation);
    },
    messaging: (calculation) => "",
    extra: (calculation) => "Locations",
  },
  {
    id: "service-style",
    category: "footer",
    label: "Style of Services",
    calculate: (responses) => responses["service_style"],
    format: (calculation) => "",
    messaging: (calculation) => "",
    extra: (calculation) => calculation,
  },
  {
    id: "staffing-fte",
    category: "footer",
    label: "Staffing Full-Time Employees",
    calculate: (responses, campusId) => {
      const full_time_staff = Number(responses["full_time_staff"]);
      const part_time_hours = Number(responses["part_time_hours"]);
      return {
        full_time_staff,
        part_time_hours,
        staffing_fte: full_time_staff + part_time_hours / 40,
      };
    },
    format: (calculation) => {
      return num(calculation.staffing_fte);
    },
    messaging: (calculation) =>
      `${num(calculation.full_time_staff)} full-time staff plus ${num(
        calculation.part_time_hours
      )} part-time hours`,
  },
  {
    id: "volunteer-leaders",
    category: "footer",
    label: "Volunteer Leaders",
    calculate: (responses) => responses["volunteer_leaders"],
    format: (calculation) => {
      return num(calculation);
    },
    messaging: (calculation) => "",
  },
];

export default function getResults(timeSubmitted) {
  return moment(timeSubmitted).isAfter(V3_RELEASE_DATE)
    ? v3CovidResults
    : moment(timeSubmitted).isAfter(COVID_RELEASE_DATE)
      ? postCovidResults
      : preCovidResults;
}
