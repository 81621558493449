import React, { Component } from "react";
import moment from "moment";
import { db } from "./constants";
import getResults from "./results";
import PDFLink from "./pdf/PDFLink";
const Json2csvParser = require("json2csv").Parser;

const COVID_RELEASE_DATE = new Date(2020, 9, 2).getTime();

function downloadCsv(csvContent, name) {
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", name + ".csv");
  document.body.appendChild(link); // Required for FF

  link.click();
}

class Assessment extends Component {
  state = {
    expanded: false,
  };

  deleteAssessment = () => {
    if (
      window.confirm(
        `Are you sure you want to delete Assessment ${this.props.index +
          1} from ${this.props.church.name}? This is not recoverable.`
      )
    ) {
      db.ref("church")
        .child(this.props.churchId)
        .child("assessments")
        .child(this.props.id)
        .remove();
    }
  };
  editCustomField = async (churchId, assessmentId, e) => {
    const el = e.target;
    const { name, value } = el;
    const snap = await db
      .ref("church")
      .child(churchId)
      .child("assessments")
      .child(assessmentId)
      .child("customFields")
      .child(name)
      .once("value");
    const prevValue = snap.val();

    if (!value || value == prevValue) {
      return;
    }

    if (
      window.confirm(`Are you sure you want to change ${name} to ${value}?`)
    ) {
      // update db with new value
      db.ref("church")
        .child(churchId)
        .child("assessments")
        .child(assessmentId)
        .child("customFields")
        .update({ [name]: value });
    } else {
      // reset field to old value
      el.value = prevValue;
    }
  };
  editResponseField = async (churchId, assessmentId, e) => {
    const el = e.target;
    const { name, value } = el;
    const snap = await db
      .ref("church")
      .child(churchId)
      .child("assessments")
      .child(assessmentId)
      .child("responses")
      .child(name)
      .once("value");
    const prevValue = snap.val();

    if (!value || value == prevValue) {
      return;
    }

    const nextValue = value.trim().replace(/,/g, "");
    if (
      window.confirm(`Are you sure you want to change ${name} to ${nextValue}?`)
    ) {
      // update db with new value
      db.ref("church")
        .child(churchId)
        .child("assessments")
        .child(assessmentId)
        .child("responses")
        .update({ [name]: nextValue });
    } else {
      // reset field to old value
      el.value = prevValue;
    }
  };
  getResponseLabel = (key) => {
    let label = key;
    if (key.includes("@")) {
      const [name, index] = key.split("@");
      label = name + " for " + this.props.assessment.responses.campuses[index];
    }
    return label.split("_").join(" ");
  };
  render() {
    var { assessment, index, customFields, churchId, id } = this.props;
    var { expanded } = this.state;
    return (
      <div className="card" style={{ marginTop: 10 }}>
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          onClick={(e) => {
            if (assessment.timeSubmitted) {
              this.setState({ expanded: !expanded });
            } else {
              window.alert("Assessment is still in progress.");
            }
          }}
        >
          <p>Assessment #{index + 1}</p>
          {assessment.timeSubmitted ? (
            <p className="small">
              {moment(assessment.timeSubmitted).format("MMM YYYY")} (
              {moment(assessment.timeSubmitted).fromNow()})
            </p>
          ) : (
            <p className="small">In Progress</p>
          )}
        </div>
        {expanded && (
          <div>
            <hr style={{ opacity: 0.3 }} />
            <div className="row">
              {assessment.responses && (
                <PDFLink
                  results={getResults(assessment.timeSubmitted)}
                  responses={assessment.responses}
                  averages={
                    moment(assessment.timeSubmitted).isAfter(COVID_RELEASE_DATE)
                      ? {}
                      : this.props.averages
                  }
                  church={this.props.church}
                  logo={require("./assets/logo.png")}
                  timeSubmitted={assessment.timeSubmitted}
                  className="button"
                />
              )}
              <button onClick={this.deleteAssessment}>Delete</button>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <h4>Responses</h4>
                {assessment.responses && (
                  <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    {Object.keys(assessment.responses).map((x) => {
                      const InputComponent =
                        x === "campuses" ? "textarea" : "input";
                      return (
                        <li key={x} style={{ position: "relative" }}>
                          <label
                            style={{
                              position: "absolute",
                              left: 10,
                              top: 15,
                              fontSize: 10,
                              textTransform: "capitalize",
                            }}
                          >
                            {this.getResponseLabel(x)}
                          </label>
                          <InputComponent
                            name={x}
                            rows={3}
                            disabled={x === "campuses"}
                            defaultValue={assessment.responses[x]}
                            style={{
                              margin: 0,
                              width: 300,
                              textAlign: "right",
                              paddingLeft: 100,
                              boxSizing: "border-box",
                            }}
                            onBlur={(e) =>
                              this.editResponseField(churchId, id, e)
                            }
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div style={{ width: "50%" }}>
                <h4>Secret Shopper Report</h4>
                {customFields && (
                  <div>
                    {Object.keys(customFields)
                      .sort(sortSecretShopperReport)
                      .map((x) => (
                        <div key={x} style={{ position: "relative" }}>
                          <label
                            style={{
                              position: "absolute",
                              left: 10,
                              bottom: 15,
                              fontSize: 10,
                              textTransform: "capitalize",
                            }}
                          >
                            {x.split(/(?=[A-Z])/).join(" ")}
                          </label>
                          <input
                            type="number"
                            style={{
                              margin: 0,
                              width: 300,
                              textAlign: "right",
                            }}
                            name={x}
                            defaultValue={
                              assessment.customFields
                                ? assessment.customFields[x]
                                : ""
                            }
                            onBlur={(e) =>
                              this.editCustomField(churchId, id, e)
                            }
                          />
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

class Church extends Component {
  state = {
    expanded: false,
  };
  downloadResponsesCSV = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      var church = this.props.data;
      var responses = [];
      Object.values(church.assessments).forEach((assessment) => {
        let nonCampusData = {}; // { ... }
        let campusesData = {}; // { 1: { ... }, 2: { ... } }

        if (!assessment.responses) {
          console.log("No responses found for assessment", assessment);
          return;
        }

        Object.keys(assessment.responses).forEach((x) => {
          if (x.includes("@")) {
            let [fieldName, campusId] = x.split("@");
            campusesData[campusId] = {
              ...campusesData[campusId],
              [fieldName]: assessment.responses[x],
              campus_name: assessment.responses.campuses[campusId],
            };
          } else {
            nonCampusData[x] = assessment.responses[x];
          }
        });

        function formatDate(date) {
          return moment(date).format("MM/DD/YYYY");
        }
        let metaData = {
          assessment_time_started: formatDate(assessment.timeStarted),
          assessment_time_submitted: formatDate(assessment.timeSubmitted),
          church_name: church.name,
          church_location: church.location,
          church_email: church.email,
          church_time_created: formatDate(church.timeCreated),
        };

        if (assessment.responses.campuses) {
          Object.keys(campusesData).forEach((x) => {
            let campusData = campusesData[x];
            let newResponse = {
              ...assessment.customFields,
              ...nonCampusData,
              ...campusData,
              ...metaData,
            };
            delete newResponse.campuses;
            responses.push(newResponse);
          });
        } else {
          let newResponse = {
            ...assessment.customFields,
            ...nonCampusData,
            ...metaData,
          };
          delete newResponse.campuses;
          responses.push(newResponse);
        }
      });

      console.log("entries", responses);

      const parser = new Json2csvParser(opts);
      const fields = [];

      var fieldsObj = {};
      responses.forEach((x) => {
        Object.keys(x).forEach((key) => {
          if (!fieldsObj[key]) {
            fieldsObj[key] = true;
            fields.push(key);
          }
        });
      });

      const opts = { fields };
      const csvContent = parser.parse(responses);

      downloadCsv(
        "data:text/csv;charset=utf-8," + csvContent,
        `${church.name} Responses`
      );
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    var { data, id } = this.props;
    var { expanded } = this.state;

    return (
      <div
        className="card"
        id={this.props.id}
        style={{
          zIndex: expanded ? 10 : 1,
          position: "relative",
          borderColor: expanded ? "black" : "#ddd",
          opacity: data.assessments ? 1 : 0.7,
        }}
      >
        <div onClick={(e) => this.setState({ expanded: !expanded })}>
          <div className="row">
            <div>
              <p style={{ fontWeight: 600 }}>
                {data.name}
                {!data.assessments && ` (Not started)`}
              </p>
              <p className="small" style={{ margin: 0 }}>
                {data.location || "No Location"} • {data.email || "Email"}
              </p>
            </div>
            <div>
              <p className="small" style={{ margin: 0 }}>
                {moment(data.timeCreated).format("MMM YYYY")} (
                {moment(data.timeCreated).fromNow()})
              </p>
              <a
                className="small"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={this.downloadResponsesCSV}
              >
                Download CSV
              </a>
            </div>
          </div>
        </div>
        {expanded &&
          data.assessments && (
            <div>
              {Object.keys(data.assessments).map((x, i) => (
                <Assessment
                  churchId={id}
                  church={this.props.data}
                  customFields={this.props.customFields}
                  assessment={data.assessments[x]}
                  averages={this.props.averages}
                  id={x}
                  index={i}
                  key={i}
                />
              ))}
            </div>
          )}
      </div>
    );
  }
}

class Churches extends Component {
  state = {
    search: "",
  };

  get churches() {
    const search = this.state.search.toUpperCase();
    return Object.keys(this.props.churches)
      .filter((x) => {
        if (search) {
          const church = this.props.churches[x];
          return (
            (church.name && church.name.toUpperCase().includes(search)) ||
            (church.email && church.email.toUpperCase().includes(search)) ||
            (church.location && church.location.toUpperCase().includes(search))
          );
        }
        return true;
      })
      .sort((a, b) => {
        return (
          this.props.churches[b].timeCreated -
          this.props.churches[a].timeCreated
        );
      });
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value });
  };

  downloadChurchCSV() {
    var responses = [];

    Object.keys(this.props.churches).forEach((churchId) => {
      let church = this.props.churches[churchId];
      if (church.assessments) {
        Object.values(church.assessments).forEach((assessment) => {
          let nonCampusData = {}; // { ... }
          let campusesData = {}; // { 1: { ... }, 2: { ... } }

          if (!assessment.responses) {
            console.log("No responses found for assessment", assessment);
            return;
          }

          Object.keys(assessment.responses).forEach((x) => {
            if (x.includes("@")) {
              if (!assessment.responses.campuses) {
                console.log("No campuses found in responses");
                return;
              }

              let [fieldName, campusId] = x.split("@");
              campusesData[campusId] = {
                ...campusesData[campusId],
                [fieldName]: assessment.responses[x],
                campus_name: assessment.responses.campuses[campusId],
              };
            } else {
              nonCampusData[x] = assessment.responses[x];
            }
          });

          function formatDate(date) {
            return moment(date).format("MM/DD/YYYY");
          }
          let metaData = {
            church_id: churchId,
            assessment_time_started: formatDate(assessment.timeStarted),
            assessment_time_submitted: formatDate(assessment.timeSubmitted),
            church_name: church.name,
            church_location: church.location,
            church_email: church.email,
            church_time_created: formatDate(church.timeCreated),
          };

          if (assessment.responses.campuses) {
            Object.keys(campusesData).forEach((x) => {
              let campusData = campusesData[x];
              let newResponse = {
                ...assessment.customFields,
                ...nonCampusData,
                ...campusData,
                ...metaData,
              };
              delete newResponse.campuses;
              responses.push(newResponse);
            });
          } else {
            let newResponse = {
              ...assessment.customFields,
              ...nonCampusData,
              ...metaData,
            };
            delete newResponse.campuses;
            responses.push(newResponse);
          }
        });
      }
    });

    console.log("entries", responses);

    const parser = new Json2csvParser(opts);
    const fields = [];

    var fieldsObj = {};
    responses.forEach((x) => {
      Object.keys(x).forEach((key) => {
        if (!fieldsObj[key]) {
          fieldsObj[key] = true;
          fields.push(key);
        }
      });
    });

    const opts = { fields };
    const csvContent = parser.parse(responses);

    downloadCsv(
      "data:text/csv;charset=utf-8," + csvContent,
      "VitalSignsExport"
    );
  }

  render() {
    if (!this.props.churches) {
      return null;
    }

    return (
      <div>
        <div style={{ position: "absolute", top: 90, right: 20 }}>
          <p
            style={{
              display: "inline",
              paddingRight: 10,
              cursor: "pointer",
              textDecoration: "underline",
              fontSize: 12,
            }}
            onClick={this.downloadChurchCSV.bind(this)}
          >
            Download CSV
          </p>
        </div>
        <input
          type="search"
          placeholder="Search churches..."
          value={this.state.search}
          onChange={this.handleSearch}
          style={{ width: "100%" }}
        />
        {this.churches.map((x) => (
          <Church
            customFields={this.props.customFields}
            key={x}
            id={x}
            data={this.props.churches[x]}
            averages={this.props.averages}
          />
        ))}
      </div>
    );
  }
}

const customFieldsOrder = [
  // pre visit
  "website",
  "invitingFacility",
  // facility
  "parkingLot",
  "facilityDesign",
  "interiorDesign",
  "signage",
  // guest services
  "guestServicesTeam",
  "informationCenter",
  "worshipProgram",
  "auditoriumEnvironment",
  "exitExperience",
  // childrens ministry
  "environment",
  "safetyAndSecurity",
  "staffing",
  // worship service
  "welcome",
  "musicSinging",
  "production",
  "hostedSpotsAnnouncements",
  "serviceFlow",
  // message
  "engagement",
  "practicalContent",
  "storiesIllustrations",
  "application",
  // overall
  "overallExperience",
];

function sortSecretShopperReport(a, b) {
  return customFieldsOrder.indexOf(a) - customFieldsOrder.indexOf(b);
}

export default Churches;
