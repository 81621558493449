import React from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"

const UNSTUCK_GREEN = "#B1C63A"
const BACKGROUND = "#F3F2F1"

const smallText = {
  color: "#666",
  fontSize: 8,
  lineHeight: 1.15,
}

const largeText = {
  color: "#222",
  fontSize: 14,
  lineHeight: 1.15,
  marginBottom: -5,
}

const Label = ({ label, labelLeft }) => {
  let styles = {
    position: "absolute",
    top: -10,
    padding: 5,
    width: 100,
  }
  if (labelLeft) {
    styles.right = "100%"
    styles.textAlign = "right"
  } else {
    styles.left = "100%"
  }

  return <View style={styles}>{label}</View>
}

const Line = ({ offset = 0, label, labelLeft }) => {
  return (
    <View
      style={{
        zIndex: 2,
        position: "absolute",
        top: `${offset * 100 || 0}%`,
        left: 0,
        right: 0,
        height: 1,
        backgroundColor: "#ddd",
      }}>
      <Label label={label} labelLeft={labelLeft} />
    </View>
  )
}

const Bar = ({ offset = 0, label, labelLeft }) => {
  return (
    <View
      style={{
        zIndex: 1,
        position: "absolute",
        top: `${offset * 100 || 0}%`,
        left: 0,
        right: 0,
        height: `${100 - offset * 100 || 0}%`,
        backgroundColor: UNSTUCK_GREEN,
        borderTop: `2px solid black`,
      }}>
      <Label label={label} labelLeft={labelLeft} />
    </View>
  )
}

const Graph = ({ top, bottom, avg, score, format = n => n, labels }) => {
  const lowest = Math.min(top, bottom, avg, score)
  const highest = Math.max(top, bottom, avg, score)
  const range = highest - lowest
  const topOffset = (highest - top) / range
  const bottomOffset = (highest - bottom) / range
  const avgOffset = (highest - avg) / range
  const scoreOffset = (highest - score) / range
  const avgScoreDiff = Math.abs(avgOffset - scoreOffset)
  const topLabel = `${format(top)} ${(labels && labels.top) || "(top 10%)"}`
  const bottomLabel = `${format(bottom)} ${(labels && labels.bottom) ||
    "(bottom 10%)"}`
  const avgLabel = (labels && labels.avg) || "average"

  return (
    <View
      style={{
        zIndex: 1,
        flexShrink: 0,
        position: "relative",
        width: 40,
        height: 100,
        backgroundColor: "#eee",
      }}>
      <View
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: -5,
          height: 5,
          width: "100%",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: BACKGROUND,
        }}
      />
      <View
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: -5,
          height: 5,
          width: "100%",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          backgroundColor: UNSTUCK_GREEN,
        }}
      />
      <Bar
        offset={scoreOffset}
        label={
          <View>
            <Text style={largeText}>{format(score)}</Text>
            <Text style={smallText}>your score</Text>
          </View>
        }
        labelLeft
      />
      <Line
        offset={topOffset}
        label={<Text style={smallText}>{topLabel}</Text>}
      />
      <Line
        offset={bottomOffset}
        label={<Text style={smallText}>{bottomLabel}</Text>}
      />
      <Line
        offset={avgOffset}
        labelLeft={avgScoreDiff > 0.2}
        label={
          <View>
            <Text style={largeText}>{format(avg)}</Text>
            <Text style={smallText}>{avgLabel}</Text>
          </View>
        }
      />
    </View>
  )
}

export default Graph
