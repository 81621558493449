import React, { Component } from "react"

class App extends Component {
  componentDidMount() {
    this.setState({
      email: "",
      password: "",
    })
  }
  render() {
    if (!this.state) {
      return null
    }
    return (
      <form
        style={{ textAlign: "center", paddingTop: 100 }}
        onSubmit={e => {
          e.preventDefault()
          this.props.onSignIn(this.state.email, this.state.password)
        }}>
        <img
          src={require("./assets/logo.png")}
          className="App-logo"
          alt="logo"
        />
        <p style={{ paddingTop: 20 }}>Admin Login</p>
        <input
          type="email"
          onChange={e =>
            this.setState({
              email: e.target.value,
            })
          }
          placeholder="Email"
          value={this.state.email}
          style={{ margin: "5px auto" }}
        />
        <input
          type="password"
          onChange={e =>
            this.setState({
              password: e.target.value,
            })
          }
          placeholder="Password"
          value={this.state.password}
          style={{ margin: "5px auto" }}
        />
        <button type="submit" className="login">
          Login
        </button>
      </form>
    )
  }
}

export default App
