import React from "react";
import moment from "moment";
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
  Font,
} from "@react-pdf/renderer";
import pluralize from "pluralize";
import { assessmentData, calcNetPromoter } from "./teams-assessment-data";

Font.register({
  family: "Lato",
  src: "/fonts/Lato-Regular.ttf",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#fff",
    fontFamily: "Lato",
  },
  heading: {
    textAlign: "center",
    fontSize: 24,

    fontFamily: "Lato",
  },
  cover: {
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: 200,
    marginBottom: 100,
  },
  church: {
    fontSize: 28,
    marginBottom: 5,
    fontFamily: "Lato",
  },
  subtitle: {
    fontSize: 18,
    fontFamily: "Lato",
  },
  date: {
    marginTop: 100,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  result: {
    width: "100%",
    flexDirection: "row",
    alignItems: "stretch",
  },
  caption: {
    width: "65%",
    flexGrow: 1,
    flexShrink: 1,
    padding: 20,
    justifyContent: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: "700",
    marginBottom: 15,
    fontFamily: "Lato",
  },
  description: {
    fontSize: 12,
  },
  figure: {
    width: "35%",
    height: 180,
    flexShrink: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  questionBlock: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 50px 10px 70px",
    borderBottom: "1px solid #eee",
  },
  flexBlock: {
    flexDirection: "row",
    justifyContent: "statr",
  },
  questionText: {
    fontSize: 14,
    marginRight: 30,

    fontFamily: "Lato",
  },
  questionNumberText: {
    fontSize: 14,
    width: 50,

    fontFamily: "Lato",
  },
  sectionBlock: {
    textAlign: "center",
    padding: 12,
    width: "33%",
  },
  sectionTitle: {
    fontFamily: "Lato",
    fontSize: 13,
    opacity: 0.6,
  },
  sectionScore: {
    fontSize: 30,
    fontFamily: "Lato",
  },
});

// Create Document Component
const PDFReport = ({ church, churchId, logo, isLeaderOnly, timeSubmitted }) => {
  let sectionAverages = {};
  let netPromoterTotal = 0;
  let netPromoterAverage = 0;
  let numAssessments = Object.keys(church.assessments).length;

  if (!isLeaderOnly) {
    netPromoterAverage = calcNetPromoter(church.assessments);
  } else {
    netPromoterAverage = calcNetPromoter([church.assessments[churchId]]);
  }

  return (
    <Document width={400} height={600}>
      <Page size="A4" style={styles.cover}>
        <Image src={logo} style={styles.logo} />
        <Text style={styles.church}>{church.churchName}</Text>
        <Text style={styles.church}>{church.teamName}</Text>
        <Text style={styles.subtitle}>
          Unstuck Teams Report From{" "}
          {pluralize("Assessments", numAssessments, true)}
        </Text>
        <Text style={styles.date}>
          {moment(timeSubmitted).format("MMM Do, YYYY")}
        </Text>
      </Page>
      {assessmentData.sections.map((section, sectionIndex) => {
        let sectionTotal = 0;

        return (
          <Page
            key={sectionIndex}
            size="A4"
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={styles.title}>
              {sectionIndex + 1}. {section.name}
            </Text>

            <View>
              {section.questions.map((question, questionIndex) => {
                var questionTotal = 0;
                var numAssessments = Object.keys(church.assessments).length;
                var questionAverage = 1;
                if (isLeaderOnly) {
                  questionAverage =
                    church.assessments[churchId][
                      sectionIndex + ":" + questionIndex
                    ];
                } else {
                  Object.keys(church.assessments).forEach(
                    (x) =>
                      (questionTotal +=
                        church.assessments[x][
                          sectionIndex + ":" + questionIndex
                        ])
                  );
                  questionAverage = (questionTotal / numAssessments).toFixed(1);
                }

                sectionTotal += parseFloat(questionAverage);

                sectionAverages[sectionIndex] = (sectionTotal / 12).toFixed(1);

                return (
                  <View key={questionIndex} style={styles.questionBlock}>
                    <View style={styles.flexBlock}>
                      <Text style={styles.questionNumberText}>
                        {questionIndex + 1}.
                      </Text>
                      <Text style={styles.questionText}>{question.text}</Text>
                    </View>
                    <Text style={styles.questionText}>{questionAverage}</Text>
                  </View>
                );
              })}

              <View style={{ ...styles.questionBlock, paddingTop: 40 }}>
                <View style={styles.flexBlock}>
                  <Text style={styles.questionText}>{section.name} score</Text>
                </View>
                <Text style={styles.questionText}>
                  {(sectionTotal / 12).toFixed(1)}
                </Text>
              </View>
            </View>
          </Page>
        );
      })}
      <Page
        size="A4"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={styles.title}>Conclusion</Text>
        <View
          style={{
            padding: 50,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View style={styles.sectionBlock}>
            <Text style={styles.sectionTitle}>
              {assessmentData.sections[0].name}
            </Text>
            <Text style={styles.sectionScore}>{sectionAverages[0]}</Text>
          </View>
          <View style={styles.sectionBlock}>
            <Text style={styles.sectionTitle}>
              {assessmentData.sections[1].name}
            </Text>
            <Text style={styles.sectionScore}>{sectionAverages[1]}</Text>
          </View>
          <View style={styles.sectionBlock}>
            <Text style={styles.sectionTitle}>
              {assessmentData.sections[2].name}
            </Text>
            <Text style={styles.sectionScore}>{sectionAverages[2]}</Text>
          </View>
        </View>
        <View
          style={{
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 30,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View style={styles.sectionBlock}>
            <Text style={styles.sectionTitle}>
              {assessmentData.sections[3].name}
            </Text>
            <Text style={styles.sectionScore}>{sectionAverages[3]}</Text>
          </View>
          <View style={styles.sectionBlock}>
            <Text style={styles.sectionTitle}>
              {assessmentData.sections[4].name}
            </Text>
            <Text style={styles.sectionScore}>{sectionAverages[4]}</Text>
          </View>
          <View style={styles.sectionBlock}>
            <Text style={styles.sectionTitle}>
              {assessmentData.sections[5].name}
            </Text>
            <Text style={styles.sectionScore}>{sectionAverages[5]}</Text>
          </View>
        </View>

        <View style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 50 }}>
          <Text style={styles.sectionTitle}>Net Promoter Score:</Text>

          <Text style={{ ...styles.sectionScore, paddingBottom: 20 }}>
            {netPromoterAverage}
          </Text>
          <Text style={{ fontFamily: "Lato", fontSize: 11, marginBottom: 5 }}>
            *The Net Promoter Score is the world’s leading metric for measuring
            customer satisfaction and loyalty. It goes beyond measuring how
            satisfied a customer is with a company; the Net Promoter Score
            system is designed to gauge their willingness to recommend it to
            others.
          </Text>
          <Link
            style={{ marginBottom: 20, fontSize: 11, fontFamily: "Lato" }}
            src="https://blog.hubspot.com/service/what-is-a-good-net-promoter-score"
          >
            What is a good net promoter score?
          </Link>

          <Image
            src={require("./../assets/net-promoter-score.png")}
            style={{ width: "100%" }}
          />
        </View>
      </Page>
      <Page
        size="A4"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ fontFamily: "Lato", fontSize: 28, marginBottom: 20 }}>
          Next Steps
        </Text>
        <Text style={{ fontFamily: "Lato", marginBottom: 20, maxWidth: 500 }}>
          The purpose of this assessment is simple: to give you a current
          snapshot of your team health. But metrics are just a starting point.
          Let's turn the knowledge to action.
        </Text>
        <Text style={{ fontFamily: "Lato", marginBottom: 200, maxWidth: 500 }}>
          Learn how The Unstuck Group can support your church with a Ministry
          Health Assessment, Strategic Planning, and Staffing & Structure Review
          at{" "}
          <Link src="https://theunstuckgroup.com/">
            www.TheUnstuckGroup.com
          </Link>
          .
        </Text>
        <Image src={logo} style={{ width: 100 }} />
      </Page>
    </Document>
  );
};

export default PDFReport;
