import React, { Component } from "react";
import moment from "moment";
import { db } from "./constants";
import TeamPDFLink from "./pdf/TeamPDFLink";
import CombinedPDFLink from "./pdf/CombinedPDFLink";
const Json2csvParser = require("json2csv").Parser;

function downloadCsv(csvContent, name) {
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", name + ".csv");
  document.body.appendChild(link); // Required for FF

  link.click();
}

class Church extends Component {
  state = {
    expanded: false,
  };
  downloadTeamPDF = () => {};
  downloadLeadPDF = () => {};
  downloadResponsesCSV = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      var church = this.props.data;
      var responses = [];
      Object.values(church.assessments).forEach((assessment) => {
        let nonCampusData = {}; // { ... }
        let campusesData = {}; // { 1: { ... }, 2: { ... } }

        if (!assessment.responses) {
          console.log("No responses found for assessment", assessment);
          return;
        }

        Object.keys(assessment.responses).forEach((x) => {
          if (x.includes("@")) {
            let [fieldName, campusId] = x.split("@");
            campusesData[campusId] = {
              ...campusesData[campusId],
              [fieldName]: assessment.responses[x],
              campus_name: assessment.responses.campuses[campusId],
            };
          } else {
            nonCampusData[x] = assessment.responses[x];
          }
        });

        function formatDate(date) {
          return moment(date).format("MM/DD/YYYY");
        }
        let metaData = {
          assessment_time_started: formatDate(assessment.timeStarted),
          assessment_time_submitted: formatDate(assessment.timeSubmitted),
          church_name: church.churchName,
          church_location: church.location,
          church_email: church.email,
          church_time_created: formatDate(church.timeCreated),
        };

        if (assessment.responses.campuses) {
          Object.keys(campusesData).forEach((x) => {
            let campusData = campusesData[x];
            let newResponse = {
              ...assessment.customFields,
              ...nonCampusData,
              ...campusData,
              ...metaData,
            };
            delete newResponse.campuses;
            responses.push(newResponse);
          });
        } else {
          let newResponse = {
            ...assessment.customFields,
            ...nonCampusData,
            ...metaData,
          };
          delete newResponse.campuses;
          responses.push(newResponse);
        }
      });

      console.log("entries", responses);

      const parser = new Json2csvParser(opts);
      const fields = [];

      var fieldsObj = {};
      responses.forEach((x) => {
        Object.keys(x).forEach((key) => {
          if (!fieldsObj[key]) {
            fieldsObj[key] = true;
            fields.push(key);
          }
        });
      });

      const opts = { fields };
      const csvContent = parser.parse(responses);

      downloadCsv(
        "data:text/csv;charset=utf-8," + csvContent,
        `${church.churchName} Responses`
      );
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    var {
      churches,
      data,
      id,
      selectedChurchIds,
      setSelectedChurchIds,
    } = this.props;
    var { expanded } = this.state;
    var isSelected = selectedChurchIds[id];

    const handleArchive = () => {
      if (
        !window.confirm(
          "Are you sure you want to archive this team submission?"
        )
      ) {
        return;
      }

      console.log("Archiving response.");

      const church = churches[id];
      const updateObj = {
        assessments: null,
        archivedAssessments: data.assessments,
      };

      console.log(id, church);

      console.log({ id, updateObj });

      churches[id].archivedAssessments = {
        ...(churches[id].archivedAssessments || {}),
        [Date.now()]: data.assessments,
      };
      churches[id].timeCreated = Date.now();
      churches[id].expirationDate = null;
      churches[id].assessments = null;

      console.log(churches[id]);

      db.ref()
        .child("teamChurch")
        .child(id)
        .update({
          assessments: null,
          archivedAssessments: churches[id].archivedAssessments,
          timeCreated: Date.now(),
          expirationDate: null,
        });
    };

    return (
      <div
        className="card"
        id={this.props.id}
        style={{
          zIndex: expanded ? 10 : 1,
          position: "relative",
          borderColor: expanded ? "black" : "#ddd",
          opacity: data.assessments ? 1 : 0.7,
        }}
      >
        <div onClick={(e) => this.setState({ expanded: expanded })}>
          <div className="row">
            <div style={{ display: "flex" }}>
              {data.assessments ? (
                <div>
                  <input
                    type="checkbox"
                    style={{ width: 50 }}
                    checked={isSelected}
                    onChange={(e) => {
                      setSelectedChurchIds({
                        ...selectedChurchIds,
                        [id]: e.target.checked ? true : false,
                      });
                    }}
                  />
                </div>
              ) : (
                <div style={{ width: 50 }} />
              )}
              <div>
                <p style={{ fontWeight: 600 }}>
                  {data.churchName} : {data.teamName}
                  {!data.assessments
                    ? !data.archivedAssessments
                      ? ` (Not started)`
                      : " (Archived)"
                    : null}
                </p>
                <p className="small" style={{ margin: 0 }}>
                  {data.location || "No Location"} • {data.email || "Email"}
                </p>
              </div>
            </div>
            <div>
              <p className="small" style={{ margin: 0, textAlign: "right" }}>
                {moment(data.timeCreated).format("MMM YYYY")} (
                {moment(data.timeCreated).fromNow()})
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                {data.assessments &&
                  Object.keys(data.assessments).length && (
                    <button onClick={() => handleArchive()}>Archive</button>
                  )}
                <div style={{ width: 10 }} />
                <TeamPDFLink
                  isLeaderOnly={true}
                  churchId={id}
                  church={data}
                  logo={require("./assets/teamslogo.png")}
                  timeSubmitted={data.dateCreated}
                  className="button"
                  downloadText="Leader PDF"
                />
                <div style={{ width: 10 }} />
                <TeamPDFLink
                  church={data}
                  logo={require("./assets/teamslogo.png")}
                  timeSubmitted={data.dateCreated}
                  className="button"
                  downloadText="Team PDF"
                />
                <div style={{ width: 10 }} />
                {data.vitalSignsChurchId &&
                  Object.values(churches).filter(
                    (x) => x.vitalSignsChurchId == data.vitalSignsChurchId
                  ).length > 1 && (
                    <TeamPDFLink
                      church={Object.values(churches)
                        .filter(
                          (x) => x.vitalSignsChurchId == data.vitalSignsChurchId
                        )
                        .reduce(
                          (accumulator, church) => {
                            Object.keys(church.assessments).forEach(
                              (assessmentId) => {
                                accumulator.assessments[assessmentId] =
                                  church.assessments[assessmentId];
                              }
                            );

                            Object.keys(church).forEach((churchProp) => {
                              if (churchProp !== "assessments") {
                                accumulator[churchProp] = church[churchProp];
                              }
                            });

                            accumulator.teamName = "Multi-team results";

                            return accumulator;
                          },
                          { assessments: {} }
                        )}
                      logo={require("./assets/teamslogo.png")}
                      timeSubmitted={data.dateCreated}
                      className="button"
                      downloadText="Multi-team PDF"
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Churches extends Component {
  state = {
    search: "",
    selectedChurchIds: {},
  };

  get churches() {
    const search = this.state.search.toUpperCase();
    let churches = Object.keys(this.props.churches)
      .filter((x) => {
        if (search) {
          const church = this.props.churches[x];
          return (
            (church.churchName &&
              church.churchName.toUpperCase().includes(search)) ||
            (church.email && church.email.toUpperCase().includes(search)) ||
            (church.location && church.location.toUpperCase().includes(search))
          );
        }
        return true;
      })
      .sort((a, b) => {
        return (
          this.props.churches[b].timeCreated -
          this.props.churches[a].timeCreated
        );
      });

    return churches;
  }

  handleSearch = (e) => {
    this.setState({ search: e.target.value });
  };

  downloadChurchCSV() {
    var responses = [];

    Object.keys(this.props.churches).forEach((churchId) => {
      let church = this.props.churches[churchId];
      if (church.assessments) {
        Object.keys(church.assessments).forEach((assessmentId) => {
          var assessmentAnswers = {};

          var assessment = { ...church.assessments[assessmentId] };

          responses.push({
            // "Church Id": church.vitalSignsChurchId,
            "Team ID": churchId,
            "Team Name": church.teamName,
            "Church Name": church.churchName,
            "Church Email": church.email,
            "Church Location": church.location,
            "Is Leader Assessment": churchId === assessmentId,
            "Net Promoter Score": assessment["5:12"],
            "Creation Date": new Date(church.timeCreated).toLocaleString(),
            "Expiration Date": church.expirationDate
              ? new Date(church.expirationDate).toLocaleString()
              : null,
            "Submission Date": new Date(
              assessment.dateCreated
            ).toLocaleString(),
            // section 1
            "My personal connection with God feels vibrant and growing.":
              assessment["0:0"],
            "I have developed genuine friendships with others on the staff.":
              assessment["0:1"],
            "I work a reasonable number of hours and am able to have adequate, uninterrupted time for my family/personal life.":
              assessment["0:2"],
            "My team leader/supervisor demonstrates care for me personally.":
              assessment["0:3"],
            "I regularly practice Sabbath (a 24 hour period to stop, unplug, and rest).":
              assessment["0:4"],
            "My team leader/supervisor and team respect and champion my family time and practice of Sabbath.":
              assessment["0:5"],
            "I am doing ministry from a place of gratitude and joy.":
              assessment["0:6"],
            "I take good care of my body by eating healthy and exercising.":
              assessment["0:7"],
            "I am NOT consistently stressed out about my ministry job.":
              assessment["0:8"],
            "I make my personal growth as a disciple of Jesus a high priority.":
              assessment["0:9"],
            "My family/significant relationships are happy that I work at our church.":
              assessment["0:10"],
            "I personally demonstrate care for my fellow staff members.":
              assessment["0:11"],
            // section 2
            "We have genuine times of prayer and being in God’s Word together.":
              assessment["1:0"],
            "Generally speaking, people demonstrate the ability and willingness to resolve conflict within our team.":
              assessment["1:1"],
            "Our leaders model integrity and honesty.": assessment["1:2"],
            "Giving your honest opinion feels safe.": assessment["1:3"],
            "We have a strong sense of family (or community) on our team.":
              assessment["1:4"],
            "There is good cooperation across departments.": assessment["1:5"],
            "People on our team admit their mistakes.": assessment["1:6"],
            "People on the team encourage and affirm one another.":
              assessment["1:7"],
            "Our team has fun together.": assessment["1:8"],
            "We celebrate our wins.": assessment["1:9"],
            "People are “present” in conversations and meetings, rather than being plugged into their electronic devices.":
              assessment["1:10"],
            "The people on our team show vulnerability and openness.":
              assessment["1:11"],
            // section 3
            "I know exactly what the “win” is for my role.": assessment["2:0"],
            "In the last 60 days, my team leader/supervisor has talked to me about my performance.":
              assessment["2:1"],
            "My role aligns with my gifting and passion.": assessment["2:2"],
            "My team leader/supervisor and I have talked about my priorities for the next few weeks/months.":
              assessment["2:3"],
            "I receive affirmation when I am doing a good job.":
              assessment["2:4"],
            "I have an intentional plan for my personal development.":
              assessment["2:5"],
            "I feel empowered to carry out my role.": assessment["2:6"],
            "I don't let technology (e-mail, text messages, social media, internet browsing) distract me from focusing on my priorities.":
              assessment["2:7"],
            "I am constantly learning, growing and striving to get better at my job.":
              assessment["2:8"],
            "I spend the majority of my time focused on the priorities and tasks most critical for my role.":
              assessment["2:9"],
            "I have the adequate resources and equipping to effectively perform my role.":
              assessment["2:10"],
            "I feel like I am able to speak into decisions that impact me.":
              assessment["2:11"],
            // section 4
            "The objectives and priorities for our team are clear.":
              assessment["3:0"],
            "Our team is effective in accomplishing its priorities in a timely manner.":
              assessment["3:1"],
            "There is clarity about who has authority to make decisions.":
              assessment["3:2"],
            "We have a collaborative mindset within our team.":
              assessment["3:3"],
            "We have alignment and are all pulling in the same direction.":
              assessment["3:4"],
            "People on our team have a strong work ethic.": assessment["3:5"],
            "Our meetings are generally productive.": assessment["3:6"],
            "We commit to an idea and see it through before we jump onto the next new idea.":
              assessment["3:7"],
            "We are held accountable for our goals and performance.":
              assessment["3:8"],
            "We use honest feedback, evaluation, and debriefing as a means to improve.":
              assessment["3:9"],
            "Our leaders are clear about where we are going as a church and we talk about it often.":
              assessment["3:10"],
            "Our team does a satisfactory job of planning ahead.":
              assessment["3:11"],
            // section 5
            "Our church is intentional in onboarding new people and orienting them to our systems.":
              assessment["4:0"],
            "I believe I am fairly compensated.": assessment["4:1"],
            "Our benefits are satisfactory.": assessment["4:2"],
            "Our reporting structure and org chart are clear.":
              assessment["4:3"],
            "We have effective internal communication.": assessment["4:4"],
            "We have a helpful and clear performance evaluation process.":
              assessment["4:5"],
            "Our church is well run (good management practices).":
              assessment["4:6"],
            "We utilize an effective project/task management system to keep people focused on priorities. ":
              assessment["4:7"],
            "We have effective and clear financial processes.":
              assessment["4:8"],
            "Generally, I am able to take my vacation time when I desire.":
              assessment["4:9"],
            "We have had training on risk issues (like abuse or someone who is suicidal).":
              assessment["4:10"],
            "Our all-staff meetings are meaningful and useful.":
              assessment["4:11"],
            // section 6
            "Expected staff behaviors/values have been clearly defined and articulated.":
              assessment["5:0"],
            "This is a fun and life-giving place to work.": assessment["5:1"],
            "Our staff culture feels healthy.": assessment["5:2"],
            "Our team takes time to celebrate our wins.": assessment["5:3"],
            "Our leaders demonstrate humility.": assessment["5:4"],
            "We have an appropriate focus on both relationships and results.":
              assessment["5:5"],
            "Leadership seeks and takes into account the input of staff and volunteers.":
              assessment["5:6"],
            "The organizational culture values a healthy rhythm/pace of life.":
              assessment["5:7"],
            "There is appropriate transparency and openness by the leadership.":
              assessment["5:8"],
            "Those on our staff are treated with utmost dignity and respect.":
              assessment["5:9"],
            "Our church does a good job of evaluating, debriefing, and making adjustments.":
              assessment["5:10"],
            "We have a culture of trust within our church staff.":
              assessment["5:11"],
          });
        });
      }
    });

    const parser = new Json2csvParser(opts);
    const fields = [];

    var fieldsObj = {};
    responses.forEach((x) => {
      Object.keys(x).forEach((key) => {
        if (!fieldsObj[key]) {
          fieldsObj[key] = true;
          fields.push(key);
        }
      });
    });

    const opts = { fields };
    const csvContent = parser.parse(responses);

    downloadCsv(
      "data:text/csv;charset=utf-8," + csvContent,
      "UnstuckTeamsExport"
    );
  }

  downloadCombinedPDF() {}

  render() {
    if (!this.props.churches) {
      return null;
    }

    return (
      <div>
        <div style={{ position: "absolute", top: 90, right: 20 }}>
          <p
            style={{
              display: "inline",
              paddingRight: 10,
              cursor: "pointer",
              textDecoration: "underline",
              fontSize: 12,
            }}
            onClick={this.downloadChurchCSV.bind(this)}
          >
            Download Teams CSV
          </p>
        </div>
        <input
          type="search"
          placeholder="Search churches..."
          value={this.state.search}
          onChange={this.handleSearch}
          style={{ width: "100%" }}
        />
        {Object.keys(this.state.selectedChurchIds).filter(
          (x) => this.state.selectedChurchIds[x]
        ).length ? (
          <CombinedPDFLink
            isLeaderOnly={false}
            churchIds={Object.keys(this.state.selectedChurchIds).filter(
              (x) => this.state.selectedChurchIds[x]
            )}
            churches={this.props.churches}
            logo={require("./assets/teamslogo.png")}
            timeSubmitted={Date.now()}
            className="button"
            downloadText="Download Combined Team PDF"
          />
        ) : null}
        {this.churches.map((x) => (
          <Church
            churches={this.props.churches}
            customFields={this.props.customFields}
            key={x}
            id={x}
            data={this.props.churches[x]}
            averages={this.props.averages}
            selectedChurchIds={this.state.selectedChurchIds}
            setSelectedChurchIds={(newChurchIds) =>
              this.setState({ selectedChurchIds: newChurchIds })
            }
          />
        ))}
      </div>
    );
  }
}

const customFieldsOrder = [
  // pre visit
  "website",
  "invitingFacility",
  // facility
  "parkingLot",
  "facilityDesign",
  "interiorDesign",
  "signage",
  // guest services
  "guestServicesTeam",
  "informationCenter",
  "worshipProgram",
  "auditoriumEnvironment",
  "exitExperience",
  // childrens ministry
  "environment",
  "safetyAndSecurity",
  "staffing",
  // worship service
  "welcome",
  "musicSinging",
  "production",
  "hostedSpotsAnnouncements",
  "serviceFlow",
  // message
  "engagement",
  "practicalContent",
  "storiesIllustrations",
  "application",
  // overall
  "overallExperience",
];

function sortSecretShopperReport(a, b) {
  return customFieldsOrder.indexOf(a) - customFieldsOrder.indexOf(b);
}

export default Churches;
