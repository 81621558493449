import React, { Component } from "react";
import { firebase, db } from "./constants";
import moment from "moment";

class Invite extends Component {
  state = {
    search: "",
    email: "",
    churchName: "",
    location: "",
    error: null,
    invites: null,
    bulkInvite: "",
    customCTA: "",
  };

  componentDidMount() {
    this.listener = db.ref().child("invite");
    this.listener.on("value", (s) => {
      this.setState({ invites: s.val() });
    });
  }
  componentWillUnmount() {
    this.listener.off();
  }

  createBulkInvite = async (e) => {
    e.preventDefault();

    if (!this.state.bulkInvite) {
      return;
    }

    var invites = this.state.bulkInvite
      .split("\n")
      .filter((y) => y)
      .map((x) => x.split(",").map((y) => y.trim()))
      .map((x) => {
        console.log(x);
        const churchName = x.shift();
        const email = x.shift();
        const location = x.join(",");

        if (!churchName || !email || !location) {
          return null;
        }

        return {
          email: email.trim().toLowerCase(),
          churchName,
          location,
          pending: true,
          customCTA: this.state.customCTA || "",
          timeCreated: firebase.database.ServerValue.TIMESTAMP,
        };
      })
      .filter((x) => x);

    if (invites.length < 1) {
      return;
    }

    var updateObj = {};
    invites.forEach((x) => (updateObj[btoa(x.email)] = x));

    await db
      .ref()
      .child("invite")
      .update(updateObj);

    this.setState({
      bulkInvite: "",
      customCTA: "",
    });
  };

  createSingleInvite = async (e) => {
    e.preventDefault();

    if (!this.state.email || !this.state.churchName || !this.state.location) {
      return;
    }

    const email = this.state.email.trim().toLowerCase();

    await db
      .ref()
      .child("invite")
      .child(btoa(email))
      .set({
        email: email,
        pending: true,
        location: this.state.location,
        churchName: this.state.churchName,
        timeCreated: firebase.database.ServerValue.TIMESTAMP,
      });

    this.setState({
      email: "",
      churchName: "",
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  get allowList() {
    let search = this.state.search.toUpperCase();
    return Object.keys(this.state.invites)
      .map((x) => ({
        ...this.state.invites[x],
        id: x,
      }))
      .filter((x) => {
        if (this.state.search) {
          return (
            (x.churchName && x.churchName.toUpperCase().includes(search)) ||
            (x.email && x.email.toUpperCase().includes(search)) ||
            (x.location && x.location.toUpperCase().includes(search))
          );
        }
        return true;
      })
      .sort((a, b) => b.timeCreated - a.timeCreated);
  }

  render() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <form onSubmit={this.createSingleInvite} style={{ width: "40%" }}>
          <h4>Add Single Account</h4>
          <small>Unlock Vital Signs for an email account.</small>
          <h5 style={{ marginTop: 10, marginBottom: 0 }}>Church Name</h5>
          <input
            type="text"
            name="churchName"
            placeholder="Grace Baptist Church"
            value={this.state.churchName}
            onChange={this.handleChange}
            style={{ display: "block" }}
          />
          <h5 style={{ margin: 0 }}>Email</h5>
          <input
            type="email"
            name="email"
            placeholder="admin@gracechurch.com"
            value={this.state.email}
            onChange={this.handleChange}
            style={{ display: "block" }}
          />
          <h5 style={{ margin: 0 }}>Location</h5>
          <input
            type="text"
            name="location"
            placeholder="Greenville, SC"
            value={this.state.location}
            onChange={this.handleChange}
            style={{ display: "block" }}
          />
          <button type="submit">Add to allow list</button>
        </form>

        <form onSubmit={this.createBulkInvite} style={{ width: "60%" }}>
          <h4>Add Multiple Accounts</h4>
          <small>Unlock Vital Signs for multiple accounts</small>
          <h5 style={{ marginTop: 10, marginBottom: 0 }}>Account Entries</h5>
          <p style={{ margin: 0, fontSize: 14 }}>
            Each invite should be on its own line in this format:
            <br />
            <code>Church Name, Email, Location</code>
          </p>
          <textarea
            rows={3}
            name="bulkInvite"
            placeholder="Grace Baptist Church, test@test.com, Greenville SC"
            value={this.state.bulkInvite}
            onChange={this.handleChange}
          />
          <h5 style={{ margin: 0 }}>Custom Call to Action</h5>
          <p style={{ margin: 0, fontSize: 14 }}>
            This text will be displayed at the end of results after the user
            submits their answers. Leave blank to use the default messaging.
          </p>
          <textarea
            rows={3}
            name="customCTA"
            value={this.state.customCTA}
            onChange={this.handleChange}
          />
          <button style={{ display: "block" }} onClick={this.createBulkInvite}>
            Add to allow list
          </button>
        </form>

        <div style={{ width: "100%" }}>
          <h4>Allow List</h4>
          <input
            type="search"
            placeholder="Search allow list..."
            value={this.state.search}
            onChange={(e) => this.setState({ search: e.target.value })}
            style={{ width: "100%" }}
          />
          {this.state.invites && (
            <div style={{ borderTop: "1px solid #ddd" }}>
              {this.allowList.map((x, i) => (
                <div
                  data-id={x.id}
                  key={i}
                  style={{ borderBottom: "1px solid #ddd" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ fontSize: 14 }}>{x.churchName}</p>
                    <p style={{ fontSize: 13 }}>
                      {x.pending ? "Pending" : "Accepted"}
                      <br />
                      Invited: {moment(x.timeCreated).format("MMMM Do YYYY")}
                    </p>
                  </div>

                  <p
                    style={{
                      marginTop: -10,
                      fontStyle: "italic",
                      fontSize: 12,
                    }}
                  >
                    {x.email}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Invite;
