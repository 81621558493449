import React, { Component } from "react";
import { firebase, db } from "./constants";

function getCatLabel(name) {
  return name.split("-").join(" ");
}

function getFieldLabel(name, cat) {
  if (name === "avg") {
    switch (cat) {
      case "debt-to-giving":
        return "caution";
      case "attendance-change":
      case "cash-reserves-weeks":
      case "guests-to-attendance":
      case "percentage-staff-budget":
        return "target";
      default:
        return "average score";
    }
  }
  if (name === "t10") {
    if (cat === "debt-to-giving") {
      return "critical";
    }
    if (cat === "cash-reserves-weeks") {
      return "healthy";
    }
    return "upper 10 percentile";
  }
  if (name === "b10") {
    if (cat === "debt-to-giving") {
      return "manageable";
    }
    if (cat === "cash-reserves-weeks") {
      return "critical";
    }
    return "bottom 10 percentile";
  }
  return name;
}

class Averages extends Component {
  state = null;
  componentDidMount() {
    this.listener = db.ref().child("averages");

    this.listener.on("value", s => {
      this.setState(s.val());
    });
  }

  componentWillUnmount() {
    this.listener.off();
  }

  handleUpdate = categoryName => {
    return async e => {
      const el = e.target;
      const { name, value } = el;
      const snap = await db
        .ref("averages")
        .child(categoryName)
        .child(name)
        .once("value");
      const prevValue = snap.val();
      if (!value || prevValue == value) {
        return;
      }

      if (
        window.confirm(
          `Are you sure you want to change [${getCatLabel(
            categoryName
          )} ${getFieldLabel(name)}] to ${value}?`
        )
      ) {
        db.ref("averages")
          .child(categoryName)
          .update({ [name]: value });
      } else {
        el.value = prevValue;
      }
    };
  };

  render() {
    if (!this.state) {
      return null;
    }

    return (
      <div style={{ display: "flex", flexWrap: "wrap", paddingBottom: 30 }}>
        {Object.keys(this.state).map(x => (
          <div key={x} style={{ padding: 10 }}>
            <h4 style={{ marginBottom: 5, textTransform: "capitalize" }}>
              {getCatLabel(x)}
            </h4>
            {Object.keys(this.state[x]).map(y => (
              <div key={y} style={{ position: "relative" }}>
                <label
                  style={{
                    position: "absolute",
                    left: 10,
                    bottom: 15,
                    fontSize: 10,
                    pointerEvents: "none",
                    textTransform: "capitalize"
                  }}
                >
                  {getFieldLabel(y, x)}
                </label>
                <input
                  type="number"
                  name={y}
                  defaultValue={this.state[x][y]}
                  onBlur={this.handleUpdate(x)}
                  style={{
                    margin: 0,
                    width: 200,
                    textAlign: "right"
                  }}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default Averages;
