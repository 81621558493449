import React, { Component } from "react";

const itemStyle = {
  marginRight: 20,
  cursor: "pointer"
};

const selectStyle = {
  marginRight: 20,
  fontWeight: 600,
  cursor: "pointer"
};

class TabMenu extends Component {
  render() {
    return (
      <p style={{ paddingTop: 15, fontSize: 15 }}>
        {this.props.tabs.map(tab => (
          <span
            key={tab.id}
            style={this.props.tab === tab.id ? selectStyle : itemStyle}
            onClick={() => this.props.setTab(tab.id)}
          >
            {tab.display}
          </span>
        ))}
      </p>
    );
  }
}

export default TabMenu;
