import React, { Component } from "react";
import PDFReport from "./PDFReport";
import PDFReportV3 from "./PDFReportV3";
import moment from "moment";
import { V3_RELEASE_DATE } from "../results";
import { PDFDownloadLink } from "@react-pdf/renderer";

class DownloadLink extends Component {
  state = {
    loaded: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 100);
  }

  render() {
    if (this.state.loaded) {
      return (
        <PDFDownloadLink
          className={this.props.className}
          document={
            moment(this.props.timeSubmitted).isAfter(V3_RELEASE_DATE)
              ? PDFReportV3(this.props)
              : PDFReport(this.props)
          }
          fileName="Vital Signs Report.pdf"
        >
          {({ loading }) => (loading ? "Generating PDF..." : "Download PDF")}
        </PDFDownloadLink>
      );
    }

    return <a className={this.props.className}>Generating PDF...</a>;
  }
}

export default DownloadLink;
