import React, { Component } from "react";
import TeamPDFReport from "./TeamPDFReport";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";

class DownloadLink extends Component {
  state = {
    loaded: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 100);
  }

  render() {
    return (
      <div>
        <button
          onClick={async () => {
            const blob = await pdf(TeamPDFReport(this.props)).toBlob();

            console.log({ blob });

            function saveFile(blob, filename) {
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
              } else {
                const a = document.createElement("a");
                document.body.appendChild(a);
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = filename;
                a.click();
                setTimeout(() => {
                  window.URL.revokeObjectURL(url);
                  document.body.removeChild(a);
                }, 0);
              }
            }

            var fileName = "Unstuck Teams: " + this.props.downloadText + ".pdf";

            saveFile(blob, fileName);
          }}
        >
          {this.props.downloadText}
        </button>
      </div>
    );
    if (this.state.loaded) {
      return (
        <PDFDownloadLink
          className="small"
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            marginRight: 10
          }}
          document={TeamPDFReport(this.props)}
          fileName="Unstuck Teams Report.pdf"
        >
          {({ loading }) =>
            loading
              ? "Generating PDF..."
              : this.props.downloadText || "Download PDF"
          }
        </PDFDownloadLink>
      );
    }

    return <a className={this.props.className}>Generating PDF...</a>;
  }
}

export default DownloadLink;
