import React, { Component, useState, useContext } from "react";
import { firebase, db } from "./constants";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TeamsInvite = ({ invite }) => {
  /*
  console.log("Original date: " + moment(invite.timeCreated).format());
  console.log(
    "Expiration date: " +
      moment(invite.timeCreated)
        .add(10, "days")
        .format()
  );
  */
  const [expirationDate, _setExpirationDate] = useState(
    invite.expirationDate || null
  );

  const setExpirationDate = async (newDate) => {
    console.log("Setting expiration date to: " + newDate);
    await firebase
      .database()
      .ref("teamInvite")
      .child(invite.id)
      .child("expirationDate")
      .set(newDate);
    console.log("Set expiration date for invite: " + invite.id);

    _setExpirationDate(newDate);
  };

  return (
    <div style={{ borderBottom: "1px solid #ddd" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: 14 }}>
          {invite.churchName} : {invite.teamName}
        </p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <p style={{ fontSize: 11, marginBottom: 0 }}>
              Expiration date override:
            </p>
            <DatePicker
              placeholderText="Default"
              selected={expirationDate ? new Date(expirationDate) : null}
              onChange={(date) => setExpirationDate(date.getTime())}
            />
          </div>
          <p style={{ fontSize: 13, width: 100, marginLeft: 15 }}>
            {/*x.assessments ? "Accepted" : "Pending"*/}
            <br />
            (Invited: {moment(invite.timeCreated).format("MMMM Do YYYY")})
          </p>
        </div>
      </div>

      <p
        style={{
          marginTop: -10,
          fontStyle: "italic",
          fontSize: 12,
        }}
      >
        {invite.email}
      </p>
    </div>
  );
};

class Invite extends Component {
  state = {
    search: "",
    email: "",
    churchName: "",
    location: "",
    error: null,
    invites: null,
    bulkInvite: "",
  };

  componentDidMount() {
    // this.listener = db.ref().child("teamInvite");
    // this.listener.on("value", (s) => {
    //   this.setState({ invites: s.val() });
    // });
  }
  componentWillUnmount() {
    this.listener.off();
  }

  createBulkInvite = async (e) => {
    e.preventDefault();

    if (!this.state.bulkInvite) {
      return;
    }

    var invites = this.state.bulkInvite
      .split("\n")
      .filter((y) => y)
      .map((x) =>
        x
          .split(",")
          .filter((y) => y)
          .map((y) => y.trim())
      )
      .map((x) => {
        console.log(x);
        const churchName = x.shift();
        const teamName = x.shift();
        const email = x.shift();
        const location = x.join(",");

        if (!churchName || !email || !location) {
          return null;
        }

        return {
          teamName,
          churchName,
          location,
          pending: true,
          timeCreated: Date.now(),
          email: email ? email.trim().toLowerCase() : "",
        };
      })
      .filter((x) => x);

    if (invites.length < 1) {
      return;
    }

    var updateObj = {};
    invites.forEach((x) => (updateObj[btoa(x.email)] = x));

    await db
      .ref()
      .child("teamInvite")
      .update(updateObj);

    this.setState({
      bulkInvite: "",
    });
  };

  createSingleInvite = async (e) => {
    e.preventDefault();

    if (
      !this.state.email ||
      !this.state.teamName ||
      (!this.state.vitalSignsChurch &&
        (!this.state.churchName || !this.state.location))
    ) {
      return;
    }

    const email = this.state.email ? this.state.email.trim().toLowerCase() : "";

    await db
      .ref()
      .child("teamInvite")
      .child(btoa(email))
      .set({
        vitalSignsChurchId: this.state.vitalSignsChurch
          ? this.state.vitalSignsChurch
          : null,
        email: email,
        location: this.state.vitalSignsChurch
          ? this.props.vitalSignsChurches[this.state.vitalSignsChurch].location
          : this.state.location,
        churchName: this.state.vitalSignsChurch
          ? this.props.vitalSignsChurches[this.state.vitalSignsChurch].name
          : this.state.churchName,
        timeCreated: firebase.database.ServerValue.TIMESTAMP,
        teamName: this.state.teamName,
      });

    this.setState({
      email: "",
      churchName: "",
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  get allowList() {
    let search = this.state.search.toUpperCase();
    return Object.keys(this.props.invites)
      .filter((inviteKey) => {
        var x = this.props.invites[inviteKey];
        if (this.state.search) {
          return (
            x.churchName.toUpperCase().includes(search) ||
            x.email.toUpperCase().includes(search) ||
            (x.location && x.location.toUpperCase().includes(search))
          );
        }
        return true;
      })
      .map((x) => {
        return { ...this.props.invites[x], id: x };
      })
      .sort((a, b) => b.timeCreated - a.timeCreated);
  }

  render() {
    const { vitalSignsChurches } = this.props;
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <form onSubmit={this.createSingleInvite} style={{ width: "40%" }}>
          <h4>Add Single Account</h4>
          <small>Unlock Unstuck Teams for an email account.</small>

          <h5 style={{ marginTop: 10, marginBottom: 0 }}>Vital Signs Church</h5>
          <select
            style={{
              marginTop: 10,
              background: "#fff",
              width: 272,
              height: 38,
              border: "1px solid #ddd",
              borderRadius: 0,
              fontSize: 15,
            }}
            value={this.state.vitalSignsChurch}
            onChange={(e) =>
              this.setState({ vitalSignsChurch: e.target.value })
            }
          >
            <option value="">No linked Vital Signs church</option>
            {Object.keys(vitalSignsChurches)
              .sort((a, b) => {
                function compare(a, b) {
                  if (a < b) {
                    return -1;
                  }
                  if (a > b) {
                    return 1;
                  }
                  return 0;
                }
                return compare(
                  vitalSignsChurches[a].name
                    ? vitalSignsChurches[a].name.trim().toLowerCase()
                    : "",
                  vitalSignsChurches[b].name
                    ? vitalSignsChurches[b].name.trim().toLowerCase()
                    : ""
                );
              })
              .map((churchId) => (
                <option value={churchId}>
                  {vitalSignsChurches[churchId].name} -{" "}
                  {vitalSignsChurches[churchId].location}
                </option>
              ))}
          </select>

          <h5 style={{ marginTop: 10, marginBottom: 0 }}>Church Name</h5>
          <input
            type="text"
            name="churchName"
            placeholder="Grace Baptist Church"
            value={
              this.state.vitalSignsChurch
                ? vitalSignsChurches[this.state.vitalSignsChurch].name
                : this.state.churchName
            }
            onChange={this.handleChange}
            style={{
              display: "block",
              background: this.state.vitalSignsChurch ? "#ddd" : "#fff",
            }}
            disabled={this.state.vitalSignsChurch}
          />
          <h5 style={{ margin: 0 }}>Location</h5>
          <input
            type="text"
            name="location"
            placeholder="Greenville, SC"
            value={
              this.state.vitalSignsChurch
                ? vitalSignsChurches[this.state.vitalSignsChurch].location
                : this.state.location
            }
            onChange={this.handleChange}
            style={{
              display: "block",
              background: this.state.vitalSignsChurch ? "#ddd" : "#fff",
            }}
            disabled={this.state.vitalSignsChurch}
          />
          <h5 style={{ margin: 0 }}>Email</h5>
          <input
            type="email"
            name="email"
            placeholder="admin@gracechurch.com"
            value={this.state.email}
            onChange={this.handleChange}
            style={{ display: "block" }}
          />
          <h5 style={{ margin: 0 }}>Team Name</h5>
          <input
            type="text"
            name="teamName"
            placeholder="Admin Team"
            value={this.state.teamName}
            onChange={this.handleChange}
            style={{ display: "block" }}
          />
          <button type="submit">Add to allow list</button>
        </form>

        <form onSubmit={this.createBulkInvite} style={{ width: "60%" }}>
          <h4>Add Multiple Accounts</h4>
          <small>Unlock Unstuck Teams for multiple accounts</small>
          <h5 style={{ marginTop: 10, marginBottom: 0 }}>Account Entries</h5>
          <p style={{ margin: 0, fontSize: 14 }}>
            Each invite should be on its own line in this format:
            <br />
            <code>Church Name, Team Name, Email, Location</code>
          </p>
          <textarea
            rows={3}
            name="bulkInvite"
            placeholder="Grace Baptist Church, Admin Team, test@test.com, Greenville SC"
            value={this.state.bulkInvite}
            onChange={this.handleChange}
          />

          <button style={{ display: "block" }} onClick={this.createBulkInvite}>
            Add to allow list
          </button>
        </form>

        <div style={{ width: "100%", paddingTop: 50 }}>
          <h4>Allow List</h4>
          <input
            type="search"
            placeholder="Search allow list..."
            value={this.state.search}
            onChange={(e) => this.setState({ search: e.target.value })}
            style={{ width: "100%" }}
          />
          {this.props.invites && (
            <div style={{ borderTop: "1px solid #ddd" }}>
              {this.allowList.map((x, i) => (
                <TeamsInvite key={i} invite={x} />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Invite;
