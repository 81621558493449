import React from "react";
import moment from "moment";
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import PDFGraph from "./PDFGraph";

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#fff",
  },
  heading: {
    textAlign: "center",
    fontSize: 24,
  },
  cover: {
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: 200,
    marginBottom: 100,
  },
  church: {
    fontSize: 28,
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 100,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  result: {
    width: "100%",
    flexDirection: "row",
    alignItems: "stretch",
  },
  caption: {
    width: "65%",
    flexGrow: 1,
    flexShrink: 1,
    padding: 20,
    justifyContent: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: "700",
    marginBottom: 5,
  },
  description: {
    fontSize: 12,
  },
  figure: {
    width: "35%",
    // height: 180,
    flexShrink: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
});

// Create Document Component
const PDFReport = ({
  results = [],
  responses,
  averages,
  church,
  logo,
  timeSubmitted,
}) => {
  let entries = [];
  let reports = [null, ...Array.from(responses.campuses || {})].map(
    (selectedCampus, index) => {
      // first index is overall
      // cast to string so it'll be truthy
      const campusId = index ? String(index - 1) : null;
      const entryForCampus = results.filter((r) => {
        if (selectedCampus) {
          if (r.category === "finances") return false;
        } else if (responses.campuses) {
          if (r.category === "facilities") return false;
        }
        if (r.if) return r.if(responses, campusId);
        return true;
      });
      entries = [...entries, ...entryForCampus];
      return {
        name: selectedCampus,
        entries: entryForCampus,
        campusId: campusId,
      };
    }
  );

  return (
    <Document width={400} height={600}>
      <Page size="A4" style={styles.cover}>
        <Image src={logo} style={styles.logo} />
        <Text style={styles.church}>{church.name}</Text>
        <Text style={styles.subtitle}>Vital Signs Report</Text>
        <Text style={styles.date}>
          {moment(timeSubmitted).format("MMM Do, YYYY")}
        </Text>
      </Page>
      {reports.map((report) => (
        <Page key={report.name} size="A4" style={styles.page} wrap={true}>
          <View
            fixed
            render={({ pageNumber }) => (
              <Text style={styles.description}>{String(pageNumber)}</Text>
            )}
          />
          <View
            fixed
            style={{ position: "absolute", top: 40, left: 0, right: 0 }}
          >
            <Text style={styles.heading}>{report.name || "Overall"}</Text>
          </View>
          {report.entries.map((result, index) => {
            const score = result.calculate(responses, report.campusId);
            const extra = result.extra ? result.extra(score) : null;
            const messaging = result.messaging(score);
            const figure = result.format(score);
            const ranking = averages[result.id];
            return (
              <View key={index} style={styles.result} wrap={false}>
                <View style={styles.caption}>
                  <Text style={styles.title}>{result.label}</Text>
                  <Text style={styles.description}>{messaging}</Text>
                </View>
                <View style={styles.figure}>
                  {ranking ? (
                    <PDFGraph
                      top={ranking.t10}
                      bottom={ranking.b10}
                      avg={ranking.avg}
                      format={result.format}
                      score={score}
                      labels={result.labels}
                    />
                  ) : (
                    <View>
                      <Text style={{ fontSize: 24 }}>{figure}</Text>
                    </View>
                  )}
                  {extra && <Text style={{ fontSize: 12 }}>{extra}</Text>}
                </View>
              </View>
            );
          })}
        </Page>
      ))}
      <Page
        size="A4"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ fontSize: 28, marginBottom: 20 }}>Next Steps</Text>
        <Text style={{ marginBottom: 20, maxWidth: 500 }}>
          The purpose of this assessment is simple: to give you a current
          snapshot of your ministry’s health. But metrics are just a starting
          point. Let's turn the knowledge to action.
        </Text>
        <Text style={{ marginBottom: 200, maxWidth: 500 }}>
          Learn how The Unstuck Group can support your church with a Ministry
          Health Assessment, Strategic Planning, and Staffing & Structure Review
          at{" "}
          <Link src="https://theunstuckgroup.com/">
            www.TheUnstuckGroup.com
          </Link>
          .
        </Text>
        <Image src={logo} style={{ width: 100 }} />
      </Page>
    </Document>
  );
};

export default PDFReport;
