import Firebase from "firebase"

export const adminAuthUid = "93nrJbvRyie4Oxn2nn08yB8NJ0l1"

Firebase.initializeApp({
  apiKey: "AIzaSyBlqTl1yMgFVnrmF9JA65HYp8rc_n2HLWA",
  authDomain: "vital-signs-db.firebaseapp.com",
  databaseURL: "https://vital-signs-db.firebaseio.com",
  projectId: "vital-signs-db",
  storageBucket: "vital-signs-db.appspot.com",
  messagingSenderId: "150979668551",
})

export const firebase = Firebase

export const db = Firebase.database()
